import React from 'react';

const invalidOnSelectOptionsChange = () => {
  throw 'You forgot to override onSelectedOptionsChange filed!';
};

const renderOptionLabel = (option) => (<><div>{option.name}</div> </>);

export const srriFilterFields = {
  id: 'SRI',
  field: 'srri',
  label: 'Risikoklasse',
  gridSize: {xs: 12, sm: 6, md: 4},
  options: [],
  value: [],
  onSelectedOptionsChange: (newValue) => {invalidOnSelectOptionsChange()},
  renderOption: renderOptionLabel,
  multiple: true,
};

export const mainFocusFilterFields = {
  id: 'mainFocus',
  label: 'Schwerpunkt',
  field: 'main_focus',
  gridSize: {xs: 12, sm: 6, md: 4},
  options: [],
  value: [],
  onSelectedOptionsChange: (newValue) => {invalidOnSelectOptionsChange()},
  renderOption: renderOptionLabel ,
  multiple: true,
};

export const investmentGoal = {
  id: 'customerInvestmentGoal',
  label: 'Anlageziel',
  field: 'investment_goal_customer',
  gridSize: {xs: 12, sm: 6, md: 4},
  options: [],
  value: [],
  onSelectedOptionsChange: (newValue) => {invalidOnSelectOptionsChange()},
  renderOption: renderOptionLabel ,
  multiple: true,
};

export const investmentHorizon = {
  id: 'investmentHorizon',
  label: 'Anlagehorizont',
  field: 'investment_horizon',
  gridSize: {xs: 12, sm: 6, md: 4},
  options: [],
  value: [],
  onSelectedOptionsChange: (newValue) => {invalidOnSelectOptionsChange()},
  renderOption: renderOptionLabel ,
  multiple: true,
};
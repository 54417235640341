import { makeStyles } from '@material-ui/core';
import {CARD_HEADER_SIZE, TAB_HEADER_SIZE, SECTION_PADDING} from '../../constants';

export default makeStyles((theme) => ({
  widgetContainer: {
    padding: 0,
    borderRadius: 8,
    height: (props) => props.fullHeight ? '100%' : 'auto',
    borderLeft: props => props.borderLeftColor ? `5px solid ${props.borderLeftColor}` : '1px solid #D9DCDF'
  },
  widgetHeaderContainer: {
    borderBottom: props => !props.subSection && props.expanded ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
    padding: `${SECTION_PADDING}px 0px ${SECTION_PADDING}px ${SECTION_PADDING}px !important`,
    marginBottom: props => props.expanded && !props.skipContentPadding ? (props.subSection ? 8 : 16) : 0,
    zIndex: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    fontSize: props => props.isPortfolioSection ? CARD_HEADER_SIZE * 0.8 : CARD_HEADER_SIZE,
    [theme.breakpoints.down('md')]: {
      fontSize: props => props.isPortfolioSection ? CARD_HEADER_SIZE * 0.7 : CARD_HEADER_SIZE * 0.8,
    },
    minWidth: 135,
    '& > b': {
      fontFamily: 'Roboto-Bold'
    }
  },
  contentContainer: {
    padding: props => props.skipContentPadding ? '0 !important' : '0 20px',
    '&:not(.hidden)': {
      marginBottom: props => props.skipContentPadding ? '0 !important' : SECTION_PADDING
    }
  },
  widgetContainerBordered: {
    boxShadow: 'none !important',
    border: '1px solid #D9DCDF',
  },
  contentContainerBordered: {
    padding: `0 ${SECTION_PADDING}px !important`
  },
  widgetContainerWrapper: {
    boxShadow: 'none !important',
    border: 'none !important',
  },
  contentContainerWrapper: {
    padding: '0 !important',
    marginBottom: '0 !important'
  },
  widgetHeaderContainerWrapper: {
    borderBottom: 'none',
    padding: '12px 0px !important',
    paddingTop: '0 !important',
    marginBottom: 0,
    zIndex: 'auto',
  },
  titleControl: {
    display: 'flex'
  },
  headerWrapper: {
    fontSize: `${TAB_HEADER_SIZE}px !important`,
    [theme.breakpoints.down('md')]: {
      fontSize: `${TAB_HEADER_SIZE * 0.8}px !important`,
    },
  },
  portfolioName: {
    display: "inline-flex",
    flexDirection: "column",

    '& > .depot-number': {
      fontSize: 12
    }
  }
}))
import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import WarningTooltip from "../../../../components/WarningTooltip";


const ComparisonButton = (props) => {
  const Icon = () => <i className={"fa fa-exchange"} />;

  return props.variant === 'icon'
    ?
      <WarningTooltip
        title={props.text}
        icon={
          <IconButton classes={props.customClasses} color={'primary'} onClick={props.onButtonClick} disabled={props.disabled}>
            <Icon/>
          </IconButton>
        }
      />
    :
      <PrimaryButton
        id={props.id}
        variant={props.variant}
        text={props.text}
        onButtonClick={props.onButtonClick}
        icon={<Icon/>}
        disabled={props.disabled}
        customClasses={props.customClasses}
      />
};

ComparisonButton.defaultProps = {
  variant: 'text',
  text: 'Vergleich'
};

export default ComparisonButton;
import React from 'react';

import useStyles from './styles'
import {InstrumentsFilter} from "../../../Modelportfolios/components";
import {Dialog, DialogContent, Grid} from "@material-ui/core";
import {ModelPortfolioBase} from "../../../Modelportfolios/Create/ModelportfolioBase";
import {ModelportfoliosFilters} from "../../../Modelportfolios";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import {PRODUCT_TYPE} from "../../constants";
import {getSubSystemConfigItem} from "../../../../utils/utils";
import {ASSET_CLASSES_OPTIONS_VALUES} from "../../../Modelportfolios/Filters/components/ExtendedFilters/constants";


function ProductSearchExpertFiltersModal({open, onClose, onAddInstruments, ...props}) {
  const classes = useStyles();

  const handleAssetsUpdate = (assets) => {
    if (onAddInstruments(assets)) {
      onClose();
    }
  }

  return (
    <Dialog open={open} maxWidth={'lg'} fullWidth onClose={onClose}>
      <DialogContent style={{position: 'relative'}}>
        <IconButton disableRipple aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <ModelportfoliosFilters
          onAddInstruments={handleAssetsUpdate}
          disabledAssetClasses={[ASSET_CLASSES_OPTIONS_VALUES.ANLEIHEN]}
          {...props}
        />
      </DialogContent>
    </Dialog>
  )
}

const getDisabledTooltipButtonsOptions = (availableSources, key) => {
  const disabled = !availableSources.includes(key);

  return {
    disabled,
    tooltip: disabled && 'In Kürze verfügbar'
  }
}


class ProductsSearch extends ModelPortfolioBase {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      expertFiltersOpen: false,
      activeSource: null
    }

    this.handleExpertFiltersOpen = this.handleExpertFiltersOpen.bind(this);
    this.handleExpertFiltersClose = this.handleExpertFiltersClose.bind(this);
    this.handleActivateSourceClick = this.handleActivateSourceClick.bind(this);
    this.handleAddInstrumentsFiltersWithRollback = this.handleAddInstrumentsFiltersWithRollback.bind(this);

    const availableSources = getSubSystemConfigItem('product_comparison', 'available_sources') || [];

    this.extraActions = [{
      text: "Favoritenliste",
      variant: "outlined",
      onButtonClick: this.handleActivateSourceClick('favoritelist'),
      ...getDisabledTooltipButtonsOptions(availableSources, 'favorite_list'),
    }, {
      text: "Musterdepot",
      variant: "outlined",
      onButtonClick: this.handleActivateSourceClick('musterdepot'),
      ...getDisabledTooltipButtonsOptions(availableSources, 'muster_depot'),
    }, {
      text: "Vermögensverwaltung",
      variant: "outlined",
      onButtonClick: this.handleActivateSourceClick('private_investment'),
      ...getDisabledTooltipButtonsOptions(availableSources, 'investment_strategy'),
    }, {
      text: "Modellportfolio",
      variant: "outlined",
      onButtonClick: this.handleActivateSourceClick('modellportfolio'),
      ...getDisabledTooltipButtonsOptions(availableSources, 'model_portfolio'),
    }, {
      text: "Kundendepot",
      variant: "outlined",
      onButtonClick: this.handleActivateSourceClick('customer_portfolio'),
      ...getDisabledTooltipButtonsOptions(availableSources, 'customer_portfolio'),
    }]
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check for better way to sync selected assets between components
    // (critical in case we delete product from comparison list)
    if (this.props.products.length != prevProps.products.length) {
      const productsToUse = this.props.products.filter((product) => product.type === PRODUCT_TYPE.ASSET);
      this.setState({
        selectedAssets: productsToUse.map((product) => product.data)
      })
    }
  }

  handleExpertFiltersOpen() {
    this.setState({
      expertFiltersOpen: true
    })
  }

  handleExpertFiltersClose() {
    this.setState({
      expertFiltersOpen: false
    })
  }

  handleActivateSourceClick(activeSource) {
    return () => {
      this.props.onActiveSourceChange(activeSource)
    }
  }

  handleAddInstrumentsFiltersWithRollback(instruments) {
    const selectedAssetsCopy = [...this.state.selectedAssets];

    const success = this.handleAddInstrumentsFilters(instruments);
    if (!success) {
      this.setState({
        selectedAssets: selectedAssetsCopy
      });
      return false
    }

    return true;
  }

  render() {
    return (
      <>
        <InstrumentsFilter
          instruments={this.getFilterInstruments()}
          instrumentsDisabled={this.state.selectedAssets}
          loading={this.state.assetsSearch.loading}
          errors={this.state.assetsSearch.errors}
          onSearchActivate={this.handleSearchActivate}
          onAddAssetClick={this.handleAddAssetClick}
          onFiltersExtendedButtonClick={this.handleExpertFiltersOpen}
          extraActions={this.extraActions}
          disabled={this.props.disabled}
          useDisabledOnExtraActions
          WidgetProps={{
            style: {
              boxShadow: 'none'
            }
          }}
        />
        <ProductSearchExpertFiltersModal
          onAddInstruments={this.handleAddInstrumentsFiltersWithRollback}
          useButtons
          open={this.state.expertFiltersOpen}
          onClose={this.handleExpertFiltersClose}
        />
      </>
    )
  }
}

export default ProductsSearch;
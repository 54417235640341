import React from 'react';

/* Material-UI Components */
import Grid from '@material-ui/core/Grid';

/* BCA modules*/
import PDFButton from '../../../CustomerDashboardV2/components_v2/ActionButtons/PDFButton';
import PageTitle from '../../../../components/PageLayout/components/PageTitle';

import useStyles from '../../../CustomerDashboardV2/components/PortfolioNavigation/styles';


export default (props) => {
  const classes = useStyles();

  const {
    handlePdfExportClick,
    pdfExportDisabled,
    loading,
  } = props;

  return (
    <PageTitle
      id="portfolio-analyze-header-tour-element"
      title={"Vermögensübersicht"}
      rightPanelItems={
        <Grid item>
          <PDFButton
            onButtonClick={handlePdfExportClick}
            disabled={pdfExportDisabled}
            customClasses={{ root: classes.panelButton }}
            loading={loading}
          />
        </Grid>
      }
    />
  )
};

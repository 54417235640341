import React from 'react';
import _ from "lodash";
import clsx from "clsx";

import { Skeleton } from '@material-ui/lab';
import {CircularProgress, Grid, makeStyles} from "@material-ui/core";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from '@material-ui/icons/Star';
import IconButton from "@material-ui/core/IconButton";

import Link from '../../../components/Link'
import {DetailsIcon, InfoIcon} from '../../../images'
import {acceptanceCriteriasExists} from "../../Modelportfolios/utils";
import {FilteringAcceptanceCriteriasModal} from "../../Modelportfolios/components";
import { getInstrName } from "../../Modelportfolios/utils";
import {getTableOptionsActionName} from "../../../components/DashboardTable/utils";
import ComparisonButton from "../../CustomerDashboardV2/components_v2/ActionButtons/ComparisonButton";
import WarningTooltip from "../../../components/WarningTooltip";
import PDFButton from "../../CustomerDashboardV2/components_v2/ActionButtons/PDFButton";

const nameStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    fontFamily: 'Roboto-Bold',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const useActionsStyles = makeStyles(() => ({
  actionButtonItem: {
    '& .MuiIconButton-root': {
      fontSize: '1em'
    }
  }
}));

const NameCell = {
  header: "Name",
  body: {
    content: (item, options) => {

      const classes = nameStyles();

      if (options.loading) {
        return <Skeleton />
      }

      const handleClick = () => {
        options.onDetailsClick && options.onDetailsClick(item.id)
      };

      return (
        <span onClick={handleClick} className={clsx(!!options.onDetailsClick && classes.link)}>
          {item.name}
        </span>)
    }
  },
  key: 'NameCell'
};

const IsFavoriteCell = {
  header: '',
    body: {
    content: (item, options) => {

      if(!options.isFavoriteSwitchEnabled(item)) { return }

      const classes = nameStyles()

      if (options.loading) {
        return <Skeleton />  // if all options are loading skeleton is displayed
      }

      if (item.loading) {
        return <CircularProgress size={24}/>  // if this specific item is loading the circle progress is displayed
      }

      const handleClick = () => {
        options.onAddToMyStrategies({id: item.id, is_favorite: !item.is_favorite})
      }

      return (
        item.is_favorite ? (
          <StarIcon onClick={handleClick}  className={classes.link}/>
        ) : (
          <StarBorderIcon onClick={handleClick}  className={classes.link}/>
        )
      )
    }
  },
  key: 'FavoriteCellName'
}

const TypeCell = {
  header: "Typ",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.type || '-'
    }
  },
  key: 'TypeCell'
}

const SRRICell = {
  header: {content: <>Risiko-<br/>klasse</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.srri || '-'
    }
  },
  key: 'SRRICell'
};

const MainFocusCell = {
  header: {content: <>Thematischer<br/>Schwerpunkt</>},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.main_focus || '-'
    }
  },
  key: 'MainFocusCell'
};

const InvestmentGoalCell = {
  header: "Anlageziel",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.investment_goal_customer || '-'
    }
  },
  key: 'InvestmentGoalCell'
};

const InvestmentHorizonCell = {
  header: "Anlagehorizont",
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return item.investment_horizon || '-'
    }
  },
  key: 'InvestmentHorizonCell'
};

const InstrumentsCountCell = {
  header: {content: <>Anzahl<br/>Instrumente</>, justifyContent: 'center'},
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      return _.get(item, 'related_model_portfolio.instruments_count') || '-'
    }
  },
  key: 'InstrumentsCountCell'
};

const ActionsCell = {
  header: '',
  body: {
    content: (item, options) => {

      if (options.loading) {
        return <Skeleton />
      }

      const detailsTitle = getTableOptionsActionName(options, 'details');
      const classes = useActionsStyles();
      const onDetails = () => options.onDetailsClick(item.id);

      return (
        <Grid container style={{justifyContent: 'flex-start'}} spacing={1}>
          {!options.viewOnly && (
            <>
              {!!item.factsheet_link && (
                <Grid item className={classes.actionButtonItem}>
                  <PDFButton
                    text={"Factsheet herunterladen"}
                    variant={"icon"}
                    onButtonClick={() => window.open(item.factsheet_link, '_blank')}
                  />
                </Grid>
              )}
              {options.onExtend && (
                <Grid item className={classes.actionButtonItem}>
                  <ComparisonButton
                    text="Vergleich öffnen"
                    variant={"icon"}
                    onButtonClick={() => options.onExtend(item)}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item>
            {detailsTitle ? (
              <Link
                title={detailsTitle || "Details"}
                fontWeight={'normal'}
                onClick={onDetails}
                disabled={item.disabledByFilter}
              />
              ) : (
              <WarningTooltip
                title={"Detailansicht öffnen"}
                icon={
                  <IconButton color={'primary'} onClick={onDetails} disabled={item.disabledByFilter}>
                    <DetailsIcon color={item.disabledByFilter ? '#ADB6BD' : '#0092E5'}/>
                  </IconButton>
                }
              />
            )}
          </Grid>
          {options.viewOnly && options.directAddingPossible && (
            <Grid item>
              <Link
                title="Hinzufügen"
                fontWeight={'normal'}
                onClick={() => options.onDetailsClick(item.id, true)}
                disabled={item.disabledByFilter}
              />
            </Grid>
          )}
        </Grid>
      )
    }
  },
  key: 'ActionsCell'
};

export const StateCol = {
  header: '',
  body: {
    content: (item, options) => {

      const [acceptanceVisible, setAcceptanceVisibility] = React.useState(false);

      const handleShowAcceptanceCriteriasClick = () => {
        setAcceptanceVisibility(true)
      };

      const handleCloseAcceptanceCriterias = () => {
        setAcceptanceVisibility(false)
      };

      if (acceptanceCriteriasExists(item)) return (
        <>
          <InfoIcon color="#80858c" onClick={handleShowAcceptanceCriteriasClick}/>
          <FilteringAcceptanceCriteriasModal
            open={acceptanceVisible}
            onClose={handleCloseAcceptanceCriterias}
            instrumentName={getInstrName(item) || item.isin}
            criterias={item.disabledByAcceptanceCriterias} />
        </>
      );
    }
  }
};

export default {
  default: [
    {
      content: [StateCol]
    }, {
      content: [IsFavoriteCell]
    }, {
      content: [NameCell],
      className: 'name',
      orderingFunction: (item) => (item.name || '').toLowerCase()
    }, {
      content: [TypeCell],
      orderingFunction: (item) => item.type
    },{
      content: [SRRICell],
      align: 'center',
      orderingFunction: (item) => item.srri
    },{
      content: [MainFocusCell],
      orderingFunction: (item) => item.main_focus
    },{
      content: [InvestmentGoalCell],
      orderingFunction: (item) => item.investment_goal_customer
    },{
      content: [InvestmentHorizonCell],
      align: 'right',
      orderingFunction: (item) => item.investment_horizon
    }, {
      content: [InstrumentsCountCell],
      align: 'center',
      orderingFunction: (item) => _.get(item, 'related_model_portfolio.instruments_count') || 0
    }, {
      content: [ActionsCell]
    }
  ]
}
import {makeStyles} from "@material-ui/core";
import {smFontSize, xsFontSize} from "../../../utils/constants";
import {
  ERROR_BACKGROUND,
  ERROR_COLOR,
  INFO_BACKGROUND, INFO_COLOR,
  SUCCESS_BACKGROUND,
  SUCCESS_COLOR
} from '../../../themes/themeConfigurator';
import {CARD_HEADER_SIZE} from "../constants";

export default makeStyles((theme) => ({
  buttonRoot: {
    borderRadius: 2,
    height: 40,

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      height: 35,
    }
  },
  btnContainer: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    }
  },
  titleControl: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectSection: {
    display: 'flex',
    alignItems: 'center',

    '& > span': {
      fontFamily: 'Roboto-Bold',
      fontSize: 14,
      color: '#AFAFAF',
    }
  },
  selectFormControl: {
    minWidth: 140,
    marginLeft: 8,

    [theme.breakpoints.down('xs')]: {
      flex: 1,
    }
  },
  selectRoot: {
    borderRadius: 0,
    padding: 11,

    [theme.breakpoints.down('sm')]: {
      padding: 8,
    }
  },
  selectSelectMenu: {
    backgroundColor: 'red'
  },
  selectMenuPaper: {
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    border: 'none'
  },
  selectMenuItemRoot: {
    color: 'initial!important',
    fontFamily: 'Roboto-Regular!important',
    [theme.breakpoints.only('sm')]: {
      fontSize: smFontSize,
      minHeight: '2rem'
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: xsFontSize,
      minHeight: '2rem'
    },
  },
  chartSectionHeaderInfoIcon: {
    marginLeft: 15,
    color: theme.palette.primary.main
  },
  indicatorGridItem: {
    [theme.breakpoints.up(1440)]: {
      flexGrow: 0,
      maxWidth: '25%',
      flexBasis: '25%',
    }
  },
  indicatorCard: {
    minWidth: 'max-content',
    borderRadius: 8,
    color: INFO_COLOR,
    background: INFO_BACKGROUND,
    padding: '10px 12px',
    textAlign: 'center',
    height: '100%',

    '&.red': {
      color: ERROR_COLOR,
      background: ERROR_BACKGROUND,
    },
    '&.green': {
      color: SUCCESS_COLOR,
      background: SUCCESS_BACKGROUND,
    },
    '&.grey': {
      color: '#202A38',
      background: 'rgba(131, 138, 147, 0.12)',
    },
    '&.yellow': {
      color: '#FF9D00',
      background: 'rgba(255, 157, 0, 0.12)',
    }
  },
  hugeNumber: {
    fontSize: 22,
    fontFamily: 'Roboto-Bold',

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  smallTitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular'
  },
  empty: {
    fontSize: 16
  },
  sectionHeader: {
    fontSize: CARD_HEADER_SIZE,
    margin: 0,
    fontFamily: 'Roboto-Regular',
    color: '#4D4F5C'
  },
  title: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold'
  },
  helpIcon: {
    color: theme.palette.primary.main,
  },
  subHeader: {
    fontSize: '0.8em',
    position: 'relative',
    top: -5
  },
  otherAssetsChart: {
    '& svg': {
      fill: '#fff !important'
    }
  }
}))
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";

import clsx from "clsx";
import DetailsLine
  from "../../../RiskDashboard/components/CustomerSummaryCard/components/EsgProfileMatchingDetails/components/DetailsLine/DetailsLine";
import React from "react";
import useStyles from './styles';

export const StructureTable = (props) => {

  const {
    series,
    useSeriesColor=false,
    preferredColor
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));

  const renderFirstColumn = () => {
    return (
      <Grid container>
        {series.map((item, index) => (
          <Grid container className={'tr'} key={`first_col${index}`}>
            <Grid item xs={12} className={'td'}>
              <div className={classes.cellContentWrapper}>
                <div className={classes.cellContent} title={item.name}>
                  {item.name}
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
      </Grid>
    )
  }


  const renderDataColumns = () => {
    return(
      <Grid container style={{flexWrap: 'nowrap', height: '100%'}} className={classes.tbodyContent}>
        {series.map((p, pIndex) => {
          let fillColor;
          if (!!preferredColor) {
            fillColor = preferredColor;
          } else {
            fillColor = (useSeriesColor && p.color) || theme.palette.primary.main;
          }
          let weightInPercentage = (p.weight || 0) * 100;

          const displayProgressLabelOutside = Math.abs(weightInPercentage) < (isBelowMd ? 45 : 43);
          return(
            <Grid className={'tr'} key={`data_col_${pIndex}`} item xs={12}>
              <Grid item xs={12} className={clsx('td')} key={`data_col_${pIndex}_cell`}>
                <DetailsLine
                  currentValue={weightInPercentage}
                  filledPercentage={weightInPercentage}
                  backgroundHeight={24}
                  isUnderweighted={true} // passed to display percentage inside the line
                  withoutOverweightIcon={true}
                  unitOfMeasure={' %'}
                  filledPercentageColor={displayProgressLabelOutside ? 'black' : 'white'}
                  customClasses={classes}
                  fillColor={fillColor}
                  displayProgressLabelOutside={displayProgressLabelOutside}
                  useInvertedLine={weightInPercentage < 0}
                />
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <Grid container className={classes.productStructureTable}>
      <Grid item className={classes.firstColumn}>
        {renderFirstColumn()}
      </Grid>

      <Grid item style={{overflow: 'auto'}} xs={true}>
        <Grid container style={{height: '100%'}}>
          <Grid item xs={12} className={'tbody'} style={{height: 'calc(100% - 51px)'}}>
            {renderDataColumns()}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
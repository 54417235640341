import { makeStyles } from '@material-ui/core';
import {TAB_HEADER_SIZE} from "../../../containers/CustomerDashboardV2/constants";

const useAggregatedReturnSectionStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12
  },
  value: {
    fontSize: TAB_HEADER_SIZE,
    fontFamily: 'Roboto-Bold',
    [theme.breakpoints.down('md')]: {
      fontSize: TAB_HEADER_SIZE * 0.85,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: TAB_HEADER_SIZE * 0.75,
    },
  }
}));

export default useAggregatedReturnSectionStyles;
import { makeStyles, createStyles } from '@material-ui/core'
import {
  autoCompleteStyles
} from '../../TransactionsMonitoring/components/Filters/components/common_styles';

export const styles = (theme, props) => createStyles({
  header: {
    fontFamily: 'Roboto-Bold',
    fontSize: 34,
    color: '#202A38',
    margin: 0
  },
  container: {
    // backgroundColor: 'white',
    width: '100%',
    minHeight: 'calc(100% + 24px)',
    marginTop: -24,
    paddingTop: 24,
    overflowX: "auto"
  },

  tableCell: {
    '&:first-child': {
      width: 30
    },

    '&.name': {
      width: (props) => props.nameCellWidth || 300
    },

    '&:last-child': {
      width: (props) => props.actionTableCellWidth || (props.viewOnly ? 165 : 200)
    },

    fontFamily: 'Roboto-Regular',
    color: '#202A38',
    backgroundColor: 'white',
    verticalAlign: 'middle',
  },
  tableHead: {
    '& td': {
      fontFamily: 'Roboto-Bold',
      color: '#80858C',
      backgroundColor: '#eef0f2',
      verticalAlign: 'top'
    }
  },

  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    '& button >span > span': {
      fontSize: 14
    }
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  recordsOnPage: {
    fontSize: 16,
    fontWeight: 'Roboto-Regular',
    color: '#80858C'
  },
  paginationInputLabel: {
    fontSize: 14,
    color: '#202A38'
  },
  autocompleteInput: autoCompleteStyles,
  menuItem: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: '#202A38',
    backgroundColor: 'white !important',
    width: '100%',
    paddingLeft: 17,
    paddingRight: 17,

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },

    '& .MuiListItemText-root span': {
      fontFamily: 'Roboto-Regular',
      fontSize: 16,
      color: '#202A38',
    },
  },
})

export default makeStyles(styles)
import React from 'react';
import _ from 'lodash';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';


const useReturnValueStyles = makeStyles(() => ({
  value: {
    fontFamily: 'Roboto-Bold',
    whiteSpace: 'nowrap',

    '&.positive': {
      color: '#23D374',
    },
    '&.negative': {
      color: '#E50000',
    }
  }
}));

function ReturnValue({value, valueHandler}) {

  const classes = useReturnValueStyles();

  const isPositive = value >= 0;
  const className = _.isNil(value)
    ? ''
    : isPositive ? 'positive' : 'negative';

  return (
    <span className={clsx(classes.value, className)}>
      {isPositive && '+'}{valueHandler(value)}
    </span>
  )
}

export default ReturnValue;
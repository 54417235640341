import React from "react";
import UnrealizedProfitAndLossTable from "../../../../../../../../components/ChartsV2/UnrealizedProfitAndLossTable";
import DashboardChartSection from '../../../DashboardChartSection';
import useStyles from "../InstrumentListItem/styles";
import { withEuroOrDash } from '../../../../../../../../utils/utils';
import { Chip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const UnrealizedProfitAndLossItem = props => {
  const {
    portfolioTransactData,
    expanded,
    onExpandedItemsChange
  } = props;

  const classes = useStyles();

  const renderSummaryRow = (portfolio) => {
    return (
      <Chip
        key={portfolio.portfolio_id}
        className={classes.saldoChip}
        label={
          <Grid container>
            <Grid item xs={12} sm={'auto'}>Zuflüsse ({withEuroOrDash(portfolio.inflows_total)})</Grid>
            <Grid item xs={12} sm={'auto'}>
              <span className={classes.hideXs}>&nbsp;</span>- Abflüsse ({withEuroOrDash(portfolio.outflows_total * -1)})
            </Grid>
            <Grid item xs={12} sm={'auto'}>
              <span className={classes.hideXs}>&nbsp;</span>= <b>{withEuroOrDash(portfolio.transaction_balance)}</b>
            </Grid>
          </Grid>
        }
      />
    )
  };

  return (
    <DashboardChartSection
      title={"Transaktionssaldo"}
      expanded={expanded}
      onExpanded={onExpandedItemsChange}
      content={
        <UnrealizedProfitAndLossTable
          key={portfolioTransactData.portfolio_id}
          data={portfolioTransactData}
          expanded={expanded}
        />
      }
      titleControl={renderSummaryRow(portfolioTransactData)}
      showTitleControlFolded
      customClasses={{
        container: classes.tableContainer,
        contentContainer: classes.tableSection,
        headerContainer: classes.headerContainer,
        header: classes.tableSectionHeader
      }}
    />
  )
};
export default UnrealizedProfitAndLossItem;
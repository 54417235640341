// msg: It is recommended to use the custodian's online portal to ensure that the selected product can be traded on the selected stock exchange.
import React from "react";
import _ from "lodash";

import withStyles from "@material-ui/core/styles/withStyles";

import styles from "./styles";
import {
  getAssetMinOrderAmount,
  getBankMinAmount, TRANSACTION_INSTR,
  TRANSACTION_INSTR_ORDERING_FIELD_NAMES,
  TRANSACTION_TYPE_VALUES
} from "../../../../constants";
import {
  getAmountFraction,
  getAvailableOrderingFields,
  instrCustodianData,
  setInstrumentBankData,
  transactionValueToQuantity
} from "../../../../utils";
import {QTY_DECIMALS} from "../../../../../../utils/utils";

export const USE_CUSTODIAN_ONLINE_PORTAL_INFO_TEXT = 'Es wird empfohlen sich im Onlineportal der Depotstelle zu vergewissern, dass für das gewählte Produkt ein Handel am ausgewählten Börsenplatz möglich ist.'
export const TableTooltip = withStyles(styles) ((props) => {

  return(
    <div className={props.classes && props.classes.switchInfoText}>
      <i className='far fa-info-circle' />
      <p>{props.text}</p>
    </div>
  )
});


// Recursive function to find the best combination to fill the maximum number of switch outs
function findBestCombination(currentSum, currentTarget, prevUsedValues, startIdx, switchIns) {
  if (currentSum > currentTarget) return null;

  let bestResult = { sum: currentSum, valuesUsed: [...prevUsedValues] };

  // If we exactly match the target, return the combination
  if (currentSum === currentTarget) {
    return bestResult;
  }

  // Try adding each available value from the remaining ones
  for (let i = startIdx; i < switchIns.length; i++) {
    const switchIn = switchIns[i];

    // Skip if this item already been used
    if (!!switchIn.newMarketValueUsed) continue;

    // Recursive step
    const result = findBestCombination(
      _.round(currentSum + switchIn.newMarketValue, _.max([getAmountFraction(currentTarget), 2])),
      currentTarget,
      [...prevUsedValues, switchIn],
      i + 1,
      switchIns
    );
    if (result && result.sum <= currentTarget && result.sum > bestResult.sum) {
      bestResult = result;
    }
  }

  return bestResult;
}

export const prepareSwitches = (portfolio, switches, switchOutCandidate, switchOutExcluded, switchIns) => {
  // Find closest sum for each sell target

  // Sort switchIns in ascending order to use as much transactions as possible
  switchIns = _.sortBy(switchIns, 'newMarketValue');
  const emptySwitches = []; // here we will store switches were we can't put any FULL switch in

  const roundQty = (qty) => _.round(qty, portfolio.maxQtyDecimals || QTY_DECIMALS);

  // Sort in ascending order to attempt smallest first to fill as many switch outs as possible
  _.sortBy(switchOutCandidate, 'newMarketValue').forEach(switchOutItem => {
    const targetAmount = switchOutItem.newMarketValue;
    const bestResult = findBestCombination(0, targetAmount, [], 0, switchIns);

    // for switch transaction type is qty
    switchOutItem = {...switchOutItem, newMarketValue: roundQty(switchOutItem.newQty)};
    if (bestResult && !_.isEmpty(bestResult.valuesUsed)) {
      switches.push({
        switchOutItem,
        switchIns: bestResult.valuesUsed,
        remainingAmount: _.round(targetAmount - bestResult.sum, 6)
      });

      // Mark the used items in this combination as used
      bestResult.valuesUsed.forEach(switchIn => switchIn.newMarketValueUsed = true);
    } else {
      emptySwitches.push({
        switchOutItem,
        switchIns: [],
        remainingAmount: targetAmount
      });
    }
  });

  switchIns = switchIns.filter((buy) => !buy.newMarketValueUsed);
  // it makes no sense to fill less then min order amount
  const bankMinAmount = getBankMinAmount(portfolio, TRANSACTION_TYPE_VALUES.BUY);

  switches.concat(emptySwitches).forEach((switchData) => {
    if (switchData.remainingAmount > bankMinAmount) {
      switchIns.forEach((switchIn) => {
        if(switchIn.newMarketValue > bankMinAmount && switchData.remainingAmount > bankMinAmount) {
          setInstrumentBankData(switchIn, instrCustodianData(switchIn, portfolio.companyId), true);
          const minOrderAmount = _.max([bankMinAmount, getAssetMinOrderAmount(switchIn, TRANSACTION_TYPE_VALUES.BUY)]);
          const amountToSwitch = _.min([switchIn.newMarketValue, switchData.remainingAmount]);
          const remainingAmountToBuy = switchIn.newMarketValue - amountToSwitch,
            switchedInCompletely = remainingAmountToBuy === 0;

          if (amountToSwitch >= minOrderAmount && (switchedInCompletely || remainingAmountToBuy >= minOrderAmount)) {
            switchData.remainingAmount -= amountToSwitch;
            switchIn.newMarketValue = remainingAmountToBuy;

            switchData.switchIns.push({...switchIn, newMarketValue: amountToSwitch});
          }
        }
      })
    }

    if (switchData.remainingAmount > 0){
      // put remaining into sell
      const switchOutItem = switchData.switchOutItem;
      const amountToSell = switchData.remainingAmount;
      const qtyToSell = roundQty(transactionValueToQuantity(amountToSell, {data: switchOutItem}, false));
      const availableOrderingFields = getAvailableOrderingFields(portfolio, TRANSACTION_TYPE_VALUES.SELL);
      const qtyAvailable = availableOrderingFields.includes(TRANSACTION_INSTR_ORDERING_FIELD_NAMES[TRANSACTION_INSTR.qty]);

      switchOutExcluded.push({
        ...switchOutItem,
        newMarketValue: qtyAvailable ? qtyToSell : amountToSell,
        newTransactionType: qtyAvailable ? TRANSACTION_INSTR.qty : TRANSACTION_INSTR.amount,
      });
      switchOutItem.newMarketValue = roundQty(switchOutItem.newMarketValue - qtyToSell);
    }
  });

  emptySwitches.forEach((switchData) => {
    if (_.isEmpty(switchData.switchIns)) {
      // set back amount for sell instead qty
      switchData.switchOutItem.newMarketValue = switchData.remainingAmount;
      switchOutExcluded.push(switchData.switchOutItem)
    } else {
      switches.push(switchData)
    }
  });
};
import {SECTION_PADDING, TAB_HEADER_SIZE} from "../../../../constants";

const styles = theme => ({
  container: {
    backgroundColor: 'white',
    borderRadius: 5,
    boxShadow: '0px 3px 15px #00000033',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      padding: 15
    }
  },
  headerContainer: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    zIndex: 1,
    overflow: 'hidden',
  },
  headerTitleWithControlWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  header: {
    fontFamily: "'Roboto-Regular'",
    fontSize: TAB_HEADER_SIZE,
    color: '#4D4F5C',
    margin: 0,
    flex: 1,

    [theme.breakpoints.down('md')]: {
      fontSize: TAB_HEADER_SIZE * 0.8,
    }
  },
  titleControl: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),

    [theme.breakpoints.down('xs')]: {
      paddingLeft: SECTION_PADDING,
      paddingRight: 0,
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    }
  },
  contentContainer: {
    '&:not(.hidden)': {
      marginBottom: theme.spacing(2),
    },
    flex: 1,
  },
  centerContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartExplanation: {
    marginTop: '1.5em',
  }
});

export default styles;
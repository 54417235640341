import React from 'react';
import {connect} from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import {Grid, withWidth} from "@material-ui/core";
import {isWidthDown} from "@material-ui/core/withWidth";
import Collapse from "@material-ui/core/Collapse";

/* BCA Components*/
import TimeFramePicker from './PortfolioTimeSelector';

/* BCA modules */
import useStyles from './styles';
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import {isTradingEnabled} from "../../../../components/TradingStore/utils";
import Switch from "../../../../components/AssetModal/components/Switch";
import {getGuideSelector} from "../../CustomerDashboard";
import {getCustomerDashboardSelector} from "../../../../components/DashboardDataProvider/DashboardDataProvider";
import {DashboardPortfoliosSelectorContext} from "../../utils";
import PortfolioList from "../../components_v2/PortfolioList";
import ReportTypeSelector from '../PortfolioNavigation/ReportTypeSelector';
import { REPORT_TYPES } from '../../../DashboardSettings/components/CustomersList/components/ReportType/constants';
import DepotViewTypeSelector from "../../components_v2/DepotViewTypeSelector/DepotViewTypeSelector";
import OrderButton from '../../components_v2/ActionButtons/OrderButton';
import PDFButton from '../../components_v2/ActionButtons/PDFButton';
import ComparisonButton from '../../components_v2/ActionButtons/ComparisonButton';
import AlertButton from '../../components_v2/ActionButtons/AlertButton';
import BenchmarkModal from "./BenchmarkSettingsModal";
import {ExpandIconButton} from "../Widgets/components/DashboardCard/components/ExpandButton";

const mapStateToProps = (state) => ({
  customerDashboard: getCustomerDashboardSelector(state),
  guide: getGuideSelector(state)
});

const Navigation = connect(mapStateToProps)(withWidth()((props) => {
  const classes = useStyles();

  const {
    calculationDates,
    handleCalculationDatesChanged,
    portfolios,
    portfoliosLoadingError,
    portfoliosDataLoading,
    handleSelectedPortfolioChanged,
    selectedPortfolios,
    investmentData,
    alwaysScrollableList,
    isVirtual,
    dataLoading,
    stickyContainer,
    onGoToTradeDetails,
    instrumentListDataLoading,
    customerHasTradings,
    tradingSession,
    dispatch,
    customerDashboard,
    isCustomerApp,
    reportType,
    setReportType,
    calculationDatesType,
    benchmarkConfigurationEnabled,
    chartsSettings,
    onChartSettingsChange,
    handlePdfExportClick,
    pdfExportDisabled,
    includeHistoricalPortfolios,
    onAddToComparisonClick,
    dashboardSettingsLoaded,
    extraActions,
    width,
  } = props;

  const [benchmarkModalOpened, setBenchmarkModalOpened] = React.useState(false);
  const [isExpandedMobile, setExpandedMobile] = React.useState(false);

  const withOrderBtn = _.isFunction(onGoToTradeDetails) && isTradingEnabled() && !instrumentListDataLoading;
  const showDates = _.isFunction(handleCalculationDatesChanged);
  const showReportType = _.isFunction(setReportType);
  const showPortfolios = _.isFunction(handleSelectedPortfolioChanged);
  {/* sm=true -> will take all available width */}
  const itemsSize = true;

  const handleBenchmarkConfigured = (benchmarks) => {
    if (_.isEmpty(benchmarks)) {
      onChartSettingsChange('performance', 'withBenchmark', false)
    }
    if (!_.isUndefined(benchmarks)) {
      onChartSettingsChange('performance', 'currently_selected_benchmarks', benchmarks);
    }

    setBenchmarkModalOpened(false); // close modal
  };

  const isMobileScreen = isWidthDown('xs', width);
  const isVisible = !isMobileScreen || !stickyContainer || isExpandedMobile;

  return (
    <Collapse in={isVisible} collapsedHeight={30} classes={{wrapper: classes.collapseWrapper}}>
      <Grid container spacing={2} id={'investment-navigation-component'} style={{opacity: isVisible ? 1 : 0}}>
        {showPortfolios  &&
          <Grid item xs={12} sm={itemsSize} className={classes.portfoliosContainer}>
            <PortfolioList
              selectedPortfolios={selectedPortfolios}
              handleSelectedPortfolioChanged={handleSelectedPortfolioChanged}
              portfolios={portfolios}
              portfoliosLoadingError={portfoliosLoadingError}
              portfoliosDataLoading={portfoliosDataLoading}
              alwaysScrollableList={alwaysScrollableList}
              isVirtual={isVirtual}
              loading={dataLoading}
              stickyContainer={stickyContainer}
              includeHistoricalPortfolios={includeHistoricalPortfolios}
            />
          </Grid>
        }
        {showDates &&
          <Grid item xs={12} sm={itemsSize} className={classes.timeFrameContainer}>
            <TimeFramePicker
              calculationDates={calculationDates}
              calculationDatesType={calculationDatesType}
              handleCalculationDatesChanged={handleCalculationDatesChanged}
              startOfInvestment={investmentData && investmentData.start_date}
              isVirtual={isVirtual}
              loading={dataLoading}
              isMounted={dashboardSettingsLoaded}
              stickyContainer={stickyContainer}
              dispatch={dispatch}
              timeSelectorDateChange={customerDashboard && customerDashboard.time_selector_date_change}
              showNoPriceOnWeekendsWarning
              useFilterStyles
              BenchmarkProps={{
                enabled: benchmarkConfigurationEnabled,
                showBenchmark: chartsSettings.performance.withBenchmark,
                configuration: chartsSettings.performance.currently_selected_benchmarks,
                onValueChanged: () => onChartSettingsChange('performance', 'withBenchmark', !chartsSettings.performance.withBenchmark),
                onConfigurationClick: isCustomerApp ? undefined : () => setBenchmarkModalOpened(true)
              }}
            />
          </Grid>
        }
        {showReportType &&
          <Grid item xs={12} sm={itemsSize} className={classes.portfoliosContainer}>
            <DepotViewTypeSelector
              hideName={stickyContainer}
              reportType={reportType}
              setReportType={setReportType}
              loading={dataLoading}
              isMounted={dashboardSettingsLoaded}
            />
          </Grid>
        }
        {stickyContainer &&
          <Grid item style={{marginLeft: 'auto'}}>
            <Grid container spacing={1}>
              {extraActions}
              {/*<Grid item>*/}
              {/*  <AlertButton variant={"icon"}/>*/}
              {/*</Grid>*/}
              {_.isFunction(onAddToComparisonClick) && (
                <Grid item>
                  <ComparisonButton
                    variant={"icon"}
                    onButtonClick={onAddToComparisonClick}
                    customClasses={{root: classes.iconBtnRoot}}
                  />
                </Grid>
              )}
              {_.isFunction(handlePdfExportClick) && (
                <Grid item>
                  <PDFButton
                    variant={"icon"}
                    onButtonClick={handlePdfExportClick}
                    customClasses={{root: classes.iconBtnRoot}}
                    disabled={pdfExportDisabled}/>
                </Grid>
              )}
              {withOrderBtn && (
                <Grid item>
                  <OrderButton
                    customClasses={{ root: classes.buttonRoot}}
                    disabled={!customerHasTradings}
                    tradingSession={tradingSession}
                    onButtonClick={onGoToTradeDetails}
                    ordersCount={props.ordersCount}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        }

        {benchmarkModalOpened && (
          <BenchmarkModal
            onBenchmarkConfigured={handleBenchmarkConfigured}
            benchmarks={chartsSettings.performance.currently_selected_benchmarks}
          />
        )}

      </Grid>
      {stickyContainer && isMobileScreen && (
        <div className={classes.mobileExpansionPanel}>
          <ExpandIconButton expanded={isExpandedMobile} onChanged={() => setExpandedMobile(!isExpandedMobile)} />
        </div>
      )}
    </Collapse>
  )
}));


export function DashboardPortfoliosSelectorSharedState({children}) {

  const [selectedPortfolios, setSelectedPortfolios] = React.useState([]);
  const [selectedComponents, setSelectedComponents] = React.useState({});

  return (
    <DashboardPortfoliosSelectorContext.Provider value={{
      selectedPortfolios,
      selectedComponents,
      onSelectedPortfoliosChanged: setSelectedPortfolios,
      onSelectedComponentsChanged: setSelectedComponents
    }}>
      {children}
    </DashboardPortfoliosSelectorContext.Provider>
  )
}


export const StickyNavigation = connect(mapStateToProps)((props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.stickyContainer, props.guide.active)} id={'investment-navigation-component-sticky'}>
      <Navigation alwaysScrollableList stickyContainer {...props}/>
    </div>
  )
});


export default Navigation;




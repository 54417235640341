import React from 'react';
import _ from "lodash";
import clsx from "clsx";

import {Divider, IconButton} from "@material-ui/core";
import useTheme from "@material-ui/core/styles/useTheme";

import useStyles from './styles';

function Benchmark({disabled, value, configuration, onValueChanged, onConfigurationClick}) {

  const classes = useStyles();
  const theme = useTheme();

  const iconClassName = _.isEmpty(configuration) ? "far fa-eye-slash" : "far fa-eye";

  const benchmarkDisabled = disabled || _.isEmpty(configuration);

  return (
    <div className={classes.container}>
      <span className={clsx(classes.link, (disabled || !onConfigurationClick) && 'disabled')} onClick={onConfigurationClick}>Benchmark</span>
      <Divider orientation="vertical" flexItem className={classes.divider}/>
      <IconButton
        className={classes.button}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        disabled={benchmarkDisabled || !onValueChanged}
      >
        <i
          className={iconClassName}
          style={{color: value && !benchmarkDisabled ? theme.palette.primary.main : 'inherit'}}
          onClick={onValueChanged}
        ></i>
      </IconButton>
    </div>
  )
}

Benchmark.propTypes = {}

Benchmark.propDefs = {}

Benchmark.defaultProps = {}

export default Benchmark;
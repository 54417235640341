import {BaseKeyIndicatorsCard} from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/KeyIndicators";
import {parseResponse} from "../../../../../../utils/api";
import {withStyles} from "@material-ui/core";
import styles from "../../../../../VirtualPortfolioPage/Tabs/KeyFigures/items/KeyIndicators/styles";
import _ from 'lodash'

class ProViewKeyIndicatorsCard extends BaseKeyIndicatorsCard {

  componentDidUpdate(prevProps, prevState, snapshot) {
    let dateHasChanged = this.props.startDate !== prevProps.startDate || this.props.endDate !== prevProps.endDate
    let benchmarksHasChanged = JSON.stringify(this.props.benchmarks) !== JSON.stringify(prevProps.benchmarks)
    let assetsHasChanged = JSON.stringify(this.props.selectedAssets) != JSON.stringify(prevProps.selectedAssets)

    if(dateHasChanged || benchmarksHasChanged || assetsHasChanged) {
      this.getData();
    }
    if(!_.isNil(this.props.showBenchmark)  && this.state.showBenchmark !== this.props.showBenchmark) {
      this.setState({
        showBenchmark: this.props.showBenchmark
      })
    }

    if(_.get(this.props,'lastBenchmarkUpdateTime', undefined) !== _.get(prevProps, 'lastBenchmarkUpdateTime', undefined)){
      this.getData();
    }
  }

  getData() {
    this.setState({
      riskData: undefined,
      riskError: undefined,
      rfr_value: undefined,
      keyIndicatorsData: undefined,
      keyIndicatorsError: undefined,
      keyIndicatorsDataBM: undefined,
      keyIndicatorsErrorBM: undefined,
      keyIndicatorsLoading: true,
      benchmarkData: undefined,
      benchmarkError: undefined,
      riskLoading: true,
      showBenchmark: this.props.showBenchmark
    })

    this.props.dataProvider.getRiskData(
      this.props.customerId, this.props.portfolioId, this.props.startDate, this.props.endDate,
      this.props.withHistoricalPortfolios, this.props.benchmarks, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [], this.props.investmentStrategyId)
      .then(response => {
        parseResponse(response, 'risk',
          data => {
            if (_.has(data, 'volatility.min_date_message')) {
              this.handleRiskError(data['volatility']['min_date_message'])
            } else if (_.has(data, 'volatility.error')) {
              this.handleRiskError(data['volatility']['error'])
            } else {
              this.setState({
                riskError: undefined,
                riskLoading: false,
                riskData: {
                  volatility: data['volatility']['risk_metrics'],
                  benchmarkVolatility: data['benchmark_volatility']['risk_metrics'],
                  benchmarkName: data['benchmark_volatility']['benchmark_name'],
                }
              })
            }
          },
          error => {
            this.handleRiskError(error);
          }
        )
      })
      .catch(error => {
        this.handleRiskError(error)
      });

      this.props.dataProvider.getKeyIndicatorsData(
        this.props.customerId, this.props.portfolioId, this.props.startDate, this.props.endDate,
        this.props.benchmarks, !this.props.isAllAssetsSelected ? this.props.selectedAssets : [], this.props.investmentStrategyId)
      .then(response => {
        parseResponse(response, 'indicators_sharpe_ratio',
          data => {
            this.setState({
              keyIndicatorsData: data['indicators'],
              keyIndicatorsError: undefined,
              keyIndicatorsLoading: false,
            })
          },
          error => {
            this.setState({
              keyIndicatorsData: undefined,
              keyIndicatorsError: error,
              keyIndicatorsLoading: false,
            })
          }
        )

        parseResponse(response, 'indicators_sharpe_ratio_bm',
          data => {
            this.setState({
              keyIndicatorsDataBM: data['indicators'],
              keyIndicatorsErrorBM: undefined,
              keyIndicatorsLoading: false,
            })
          },
          error => {
            this.setState({
              keyIndicatorsDataBM: undefined,
              keyIndicatorsErrorBM: error,
              keyIndicatorsLoading: false,
            })
          }
        )

        this.setState({
          rfr_value: response['risk_free_rate_value'],
        })

      })
      .catch(error => {
        this.setState({
          keyIndicatorsData: undefined,
          keyIndicatorsError: error,
          keyIndicatorsLoading: false,
        })
      })
  }
}

export default withStyles(styles)(ProViewKeyIndicatorsCard)
const styles = theme => ({
  activeTabIndicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent !important",
    bottom: '7px !important',
    "& > div": {
      maxWidth: 30,
      width: "100%",
      backgroundColor: "#0092E5"
    }
  },
  scrollButtons: {
    width: 25,
    color: theme.palette.primary.main,
    boxShadow: '0px 2px 6px 2px #0000001A, 0px 1px 2px 0px #00000026',
    zIndex: 1,  // for left arrow boxShadow
    transition: 'width 0.2s ease-in, opacity 0.2s ease-in',

    '&.Mui-disabled': {
      width: 0
    }
  },
  tab: {
    paddingLeft: '24px !important',
    paddingRight: '24px !important',
    fontSize: '16px !important',
    color: '#202A38 !important',
    borderRight: '1px solid #D8DCDF !important',
    textTransform: 'none !important',
    background: 'transparent linear-gradient(180deg, #FFFFFF 0%, #F5F6F7 100%) 0% 0% no-repeat !important',
    opacity: 1,
  },
  tabSelected: {
    textShadow: '.5px 0 #202A38 !important',
    background: '#ffffff !important',
  },
  tabPanel: {
    padding: 0,
  },
  paperRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    boxShadow: '0px 3px 15px #39435233',
    overflow: 'hidden',
    width: '100%'
  },
  loadingTabContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 200
  },
  tabContentContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.only("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1),
    }
  }
});

export default styles;
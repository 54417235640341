import React from 'react';
import clsx from 'clsx';

import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import withWidth from "@material-ui/core/withWidth/withWidth";

import styles from './styles';
import PropTypes from 'prop-types'
import Tooltip from "../../Tooltip";

const PrimaryButton = props => {
  const {
    classes,
    id,
    icon,
    text,
    hideTextOn,
    onButtonClick,
    width,
    disabled,
    variant,
    color,
    customClasses,
    disableShadow,
    iconPosition,
    onRef,
    style,
    tooltip
  } = props;

  const isTextHidden = () => {
    return hideTextOn && hideTextOn.includes(width) || false;
  };

  const customClassesCopy = customClasses || {};
  const Icon = () => <span className={clsx(classes.icon, variant === 'text' && 'icon-text')}>{icon}</span>;

  const button = (
    <Button
      id={id}
      variant={variant}
      color={color}
      className={clsx(
        classes.button,
        isTextHidden() && classes.hiddenText,
        disableShadow && classes.noShadow
      )}
      onClick={onButtonClick}
      disableRipple={true}
      disabled={disabled || false}
      classes={{
        outlined: classes.outlined,
        outlinedPrimary: classes.outlinedPrimary,
        label: classes.label,
        ...customClassesCopy
      }}
      ref={onRef}
      style={style || {}}
    >
      {icon && iconPosition == 'left' && <Icon/>}
      {text && <span className={clsx(classes.text, customClassesCopy.text)}>{text}</span>}
      {icon && iconPosition == 'right' && <Icon/>}
    </Button>
  );

  if (!tooltip) {
    return button
  }

  return (
    <Tooltip title={tooltip} placement={"top"} width={600} interactive>
      <span>
        {button}
      </span>
    </Tooltip>
  )
};

PrimaryButton.propTypes = {
  iconPosition: PropTypes.oneOf(['left', 'right']),
  disableShadow: PropTypes.bool,
  text: PropTypes.node,
  onButtonClick: PropTypes.func,
};

PrimaryButton.defaultProps = {
  iconPosition: 'left',
  disableShadow: true,
  variant: 'contained',
  color: 'primary',
};

export default withStyles(styles)(withWidth()(PrimaryButton));
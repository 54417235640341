import React from 'react';
import { connect } from 'react-redux';
import setSticky from '../../utils/sticky';

/* Material UI Components*/
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import withWidth from "@material-ui/core/withWidth/withWidth";
import { MuiThemeProvider } from '@material-ui/core/styles';

/* BCA Components */
import ErrorModal from '../../components/ErrorModal';
import Navigation from './components/PortfolioNavigation';
import InvestmentDetailsNavigation, {
  DashboardPortfoliosSelectorSharedState,
  StickyNavigation
} from '../CustomerDashboardV2/components/InvestmentDetailsNavigation';
import withDashboardData from '../../components/DashboardDataProvider/DashboardDataProviderV2';
import { GuideTour } from '../../components/GuideTour';
import {
  CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE,
  PERMISSION_ERROR_MODAL_MESSAGE,
  SELECTED_LONGER_TIMEFRAME
} from '../../utils/constants'

/* BCA modules */
import {
  getFromStorage,
  SHARED_SETTINGS_KEY
} from '../../utils/storage';
import { displayWarningSnackBar } from '../../components/SnackbarProvider/actions'
import {
  getErrorMessage,
  getReportGenerationSettings,
  isSectionVisible,
  renderPortfolioDropdownName
} from "../../utils/utils";
import {REPORT_TYPES} from "../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {downloadPdf} from "../../utils/utils";

import {getGuideSteps} from "../PortfolioDashboard/guide";
import _ from "lodash";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";
import TabsContainer from "../../components/TabsContainer";
import {REPORT_TYPE_TABS, TAB} from "../CustomerDashboardV2/constants";
import {TabsSharedData} from "../CustomerDashboardV2/components_v2/TabsSharedData/TabsSharedData";
import OverviewTab from "../CustomerDashboardV2/tabs/Overview";
import PerformanceTab from "../CustomerDashboardV2/tabs/Performance";
import InstrumentsTab from "../CustomerDashboardV2/tabs/Instruments";
import PaymentPlansTab from "../CustomerDashboardV2/tabs/PaymentPlans";
import TransactionsTab from "../CustomerDashboardV2/tabs/Transactions";
import StructureTab from "../CustomerDashboardV2/tabs/Structure";
import KeyFiguresTab from "../CustomerDashboardV2/tabs/KeyFigures";
import RiskTab from "../CustomerDashboardV2/tabs/Risk";
import SustainabilityTab from "../CustomerDashboardV2/tabs/Sustainability";
import {getDefaultExpandedItems} from "../CustomerDashboardV2/utils";

const mapStateToProps = (state) => ({
  guide: state.get('guide').toJS(),
  auth: state.get('auth').toJS(),
});

const TAB_COMPONENT = {
  [TAB.OVERVIEW.id]: OverviewTab,
  [TAB.PERFORMANCE.id]: PerformanceTab,
  [TAB.INSTRUMENTS.id]: InstrumentsTab,
  [TAB.PAYMENT_PLANS.id]: PaymentPlansTab,
  // [TAB.TRANSACTIONS.id]: TransactionsTab,
  [TAB.STRUCTURE.id]: StructureTab,
  [TAB.KEY_FIGURES.id]: KeyFiguresTab,
  [TAB.RISK.id]: RiskTab,
  [TAB.SUSTAINABILITY.id]: SustainabilityTab,
};

const PortfolioDashboardV2 = connect(mapStateToProps)(withDashboardData(withWidth()((props) => {

  const {
    customer,
    portfolios,
    investmentData,
    historicalData,
    dashboardData,
    instrumentList,
    profitAndLoss,
    paymentPlans,
    payoutPlansData,
    switchPlansData,
    onSelectedPortfoliosChanged,
    onSelectedDatesChanged,
    selectedPortfolios,
    selectedDates,
    selectedDatesType,
    breakdownData,
    timeWeightedReturnData,
    isVirtual,
    dataLoading,
    selectedRange,
    chartsSettings,
    includeHistoricalPortfolios,
    handleChartSettingsChange,
    reportType
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [expandedItems, setExpandedItems] = React.useState(getDefaultExpandedItems());
  const [pdfExportBtnDisabled, setPdfExportBtnDisabled] = React.useState(false);
  const [pdfOpen, setPdfOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(TAB.OVERVIEW.id);

  // Available Tabs
  const dashboardSettings = _.get(customer, 'data.dashboard_settings', {});
  const customSettings = _.get(dashboardSettings, 'individual_settings', {});

  let availableTabs = reportType === REPORT_TYPES.CUSTOM.value
    ? REPORT_TYPE_TABS[REPORT_TYPES.PRO.value].filter(tab_id => isSectionVisible(customSettings, tab_id, reportType))
    : REPORT_TYPE_TABS[reportType];

  // if(!dashboardSettings.with_transactions_monitor){
    availableTabs = availableTabs.filter(tab_id => tab_id !== TAB.TRANSACTIONS.id);
  // }
  if(!dashboardSettings.with_payment_plans){
    availableTabs = availableTabs.filter(tab_id => tab_id !== TAB.PAYMENT_PLANS.id);
  }
  const TabComponent = TAB_COMPONENT[activeTab];
  // end Available Tabs

  React.useEffect(() => {
    setSticky('app-main', 'sticky-nav-stoper', 'investment-navigation-component-sticky', 'sticky-trigger')
    if(!_.isEmpty(availableTabs) && !availableTabs.includes(activeTab)) {
      setActiveTab(availableTabs[0]);
    }
  }, []);

  React.useEffect(() => {
    if (!props.auth.error) {
      if (investmentData.errors) {
        setErrorMessage(investmentData.errors)
      }

      if (customer.errors && customer.errors.status === 401) {
        return;
      } else if (customer.errors && customer.errors.status === 404) {
        setErrorMessage(CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE)
      } else if (customer.errors && customer.errors.status === 403) {
        setErrorMessage(PERMISSION_ERROR_MODAL_MESSAGE)
      } else {
        setErrorMessage(customer.errors)
      }

      if (getFromStorage(SHARED_SETTINGS_KEY)) {
        let appSettings = getFromStorage(SHARED_SETTINGS_KEY).app_settings || {}
        if (selectedRange.isBig && appSettings.calculation_warning_enabled) {
          props.dispatch(displayWarningSnackBar(SELECTED_LONGER_TIMEFRAME))
        }
      }
    }
  }, [
    customer.errors,
    dashboardData.errors,
    investmentData.errors,
    instrumentList.errors,
    selectedRange.id
  ]);

  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  const handleExpandedItemsChange = (path, expanded) => {
    setExpandedItems((prevState) => {
      // TODO: cloneDeep will make all objects changed
      const expandedItemsNew = _.cloneDeep(prevState);
      _.set(expandedItemsNew, path, expanded);

      return expandedItemsNew
    })
  };

  const fetchPdfFile = async (skip_expanded, skip_sub_depot_expanded, editorEnabled, editorValue, extraFiles) => {
    setPdfExportBtnDisabled(true);
    setPdfOpen(false);

    try {
      let formData = getReportGenerationSettings(customer.data && customer.data.customer_id, selectedPortfolios, portfolios,
        reportType, selectedDates, skip_expanded, skip_sub_depot_expanded, expandedItems, false, undefined, includeHistoricalPortfolios, [], chartsSettings)
      formData.append('new_design', true);
      const colors = {};
      selectedPortfolios.forEach(p => {
        colors[p.depotNumber] = p.color;
      });
      formData.append('colors', JSON.stringify(colors));
      formData.append('customer_report', true);


      await downloadPdf('reports/report/ad-hoc/', undefined, 'POST', undefined, formData)

    } catch (errors) {
      if(_.isArray(_.get(errors, 'data'))){
        // get uniq error messages and concat them to single string
        errors = Object.values(errors.data); // get list of each file errors objects
        errors =errors.map(e => _.get(e, 'file', _.isString(e) ? e : '')); // getting only error messages
        errors = _.flatten(errors); // making one plain list with all errors
        errors = [...new Set(errors)].join('\n') // joining uniq messages in one error message
      }
     setErrorMessage(getErrorMessage(errors))
    }

    setPdfExportBtnDisabled(false)
  };

  const isCustomerDataMounted = !customer.loading && !_.isEmpty(customer.data);
  const selectedPortfoliosName = renderPortfolioDropdownName(
      selectedPortfolios || [], portfolios || []);

  const actionBarProps = {
    handlePdfExportClick: () => {setPdfOpen(true)},
    pdfExportDisabled: _.isEmpty(selectedPortfolios) || pdfExportBtnDisabled,
    loading: pdfExportBtnDisabled
  };

  const navBarProps = {
    includeHistoricalPortfolios: includeHistoricalPortfolios,
    calculationDates: selectedDates,
    calculationDatesType: selectedDatesType,
    handleCalculationDatesChanged: onSelectedDatesChanged,
    portfolios: portfolios.data,
    portfoliosLoadingError: portfolios.errors,
    portfoliosDataLoading: portfolios.loading,
    handleSelectedPortfolioChanged: onSelectedPortfoliosChanged,
    selectedPortfolios: selectedPortfolios,
    dataLoading: dataLoading,
    dashboardSettingsLoaded: isCustomerDataMounted,
    instrumentListDataLoading: instrumentList.loading,
    investmentData: investmentData.data,
    chartsSettings: chartsSettings,
    onChartSettingsChange: handleChartSettingsChange,
    isCustomerApp: true,
    isVirtual: isVirtual,
    benchmarkConfigurationEnabled: true,
  };

  return (
    <React.Fragment>
      {/*<GuideTour*/}
      {/*  steps={getGuideSteps(width, UserUtils.getCustomerReportType(customer.data), withOtherAssets)}*/}
      {/*  title="Vermögensübersicht Info-Tour"*/}
      {/*/>*/}
      <Container className={`app-page-container`}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Navigation {...actionBarProps} />
          </Grid>
          <DashboardPortfoliosSelectorSharedState>
            <StickyNavigation {...navBarProps} {...actionBarProps} />
            <Grid item xs={12} id="sticky-trigger">
              <InvestmentDetailsNavigation {...navBarProps} />
            </Grid>
          </DashboardPortfoliosSelectorSharedState>
            {!_.isEmpty(availableTabs) && (
              <Grid item xs={12}>
                <TabsContainer
                  value={activeTab}
                  loading={!isCustomerDataMounted}
                  onChange={(event, id) => setActiveTab(id)}
                  tabs={Object.values(TAB).filter(tab => availableTabs.includes(tab.id))}
                  tabContent={
                    <TabsSharedData>
                      <TabComponent
                        isMounted
                        dashboardSettings={dashboardSettings}
                        customSettings={_.get(customSettings, `${activeTab}.subcategories`)}
                        selectedPortfolios={selectedPortfolios}
                        calculationDates={selectedDates}
                        calculationDatesType={selectedDatesType}
                        dashboardData={dashboardData.data}
                        dashboardDataLoading={dashboardData.loading}
                        dashboardDataLoadingError={dashboardData.errors}
                        instrumentListData={instrumentList.data}
                        instrumentListDataLoading={instrumentList.loading}
                        instrumentListDataLoadingError={instrumentList.errors}
                        historicalData={historicalData}
                        profitAndLossData={profitAndLoss}
                        paymentPlansData={paymentPlans.data}
                        paymentPlansLoading={paymentPlans.loading}
                        paymentPlansLoadingError={paymentPlans.errors}
                        payoutPlansData={payoutPlansData.data}
                        payoutPlansLoading={payoutPlansData.loading}
                        payoutPlansLoadingError={payoutPlansData.errors}
                        switchPlansData={switchPlansData.data}
                        switchPlansLoading={switchPlansData.loading}
                        switchPlansLoadingError={switchPlansData.errors}
                        breakdownData={breakdownData}
                        timeWeightedReturnData={timeWeightedReturnData}
                        benchmarkConfigurationEnabled
                        expandedItems={expandedItems}
                        chartsSettings={chartsSettings}
                        onExpandedItemsChange={handleExpandedItemsChange}
                        onChartSettingsChange={handleChartSettingsChange}
                        virtualInstrumentLink={customer.data && customer.data.user.agency.virtual_instrument_buy_template}
                        customerData={customer.data}
                        reportType={reportType}
                        investmentData={investmentData}
                        portfolios={portfolios.data}
                        aggregatedPortfolioName={selectedPortfoliosName}
                        isCustomerApp
                      />
                    </TabsSharedData>
                  }
                />
              </Grid>
            )}
          <Grid item xs={12} id={'sticky-nav-stoper'} />
        </Grid>
      </Container>
      <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
      <DownloadPdfDialog
        open={pdfOpen}
        onClose={() => {setPdfOpen(false)}}
        onExport={fetchPdfFile}
        exportBtnDisabled={!selectedPortfolios || pdfExportBtnDisabled}
        title={'Vermögensübersicht drucken'}
      >
        <DownloadPdfDialog.Expanded />
      </DownloadPdfDialog>
    </React.Fragment>
  )
})));

export default PortfolioDashboardV2;
import { makeStyles } from "@material-ui/core";
import { SECTION_PADDING } from '../../../containers/CustomerDashboardV2/constants';
import { ROTATE_ICON_STYLES } from '../common_styles';

const commonStyles = {
  ...ROTATE_ICON_STYLES,
  tableHead: {
    backgroundColor: '#f9f9f9',

    '& td': {
      color: '#80858C !important'
    }

  },
  tableCell: {
    width: 115,
    '&:nth-of-type(1)': {
      width: 40,
      paddingLeft: SECTION_PADDING
    },
    '&:nth-of-type(2)': {
      width: 'auto'
    },
    '&:nth-of-type(4)': {
      paddingRight: 15
    },
    '&:nth-of-type(1n+3)': {
      verticalAlign: 'middle',
    }
  },
  tableFooter: {
    backgroundColor: '#eef0f2',
    '& > tr': {
      height: 65
    },
    '& td': {
      verticalAlign: 'middle'
    }
  },
}

export default makeStyles(theme => ({
  ...commonStyles,
  [theme.breakpoints.down('md')]: {
    tableCell: {
      width: 100,
      '&:nth-of-type(4)': {
        width: 110,
        paddingRight: 5
      },
    }
  },
  [theme.breakpoints.down('sm')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 150,
      },
      '&:nth-of-type(3)': {
        width: 100
      },
      '&:nth-of-type(4)': {
        width: 100
      },
    }
  }
}))

export const useTradingTableClasses = makeStyles(theme => ({
  ...commonStyles,
  [theme.breakpoints.down('md')]: {
    tableCell: {
      width: 100,
      '&:nth-of-type(4)': {
        width: 110,
        paddingRight: 5
      },
    }
  },
  [theme.breakpoints.down('sm')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(2)': {
        width: 150,
      },
    }
  },

  [theme.breakpoints.only('xs')]: {
    tableCell: {
      '&:nth-of-type(1)': {
        width: 35,
      },
      '&:nth-of-type(3)': {
        width: 100
      },
    }
  },
}));
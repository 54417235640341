import React from "react";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SendReportButton from "../../PreviewStepContent/components/SendReportButton";
import useStyles from "../../../../CustomerDashboard/components/PortfolioNavigation/styles";


const NavigationButtons = props => {

  const {
    onNextClick,
    onBackClick,
    isLastTab,
    nextBtnDisabled,
    loading,
    newDesign,
    reportSettings
  } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={2} style={{marginTop:10}}>
      <Grid xs={12} sm={"auto"} item>
        <PrimaryButton
          text={"Zurück"}
          disabled={false}
          onButtonClick={onBackClick}
          style={{width: '100%'}}
          customClasses={{ root: classes.panelButton }}
        />
      </Grid>

      <Grid xs={12} sm={true} item>
        <Grid container justify="flex-end" spacing={2}>
          {isLastTab &&
            <Grid xs={12} sm={"auto"} item>
              <SendReportButton
                isButtonDisabled={false}
                newDesign={newDesign}
                reportSettings={reportSettings}
                style={{width: '100%'}}
                customClasses={{ root: classes.panelButton, text: classes.btnText }}
              />
            </Grid>
          }
          <Grid xs={12} sm={"auto"} item>
            <PrimaryButton
              icon={loading && <CircularProgress style={{height: 20, width: 20, color: 'white'}} />}
              text={isLastTab ? "Speichern & zurück zur Übersicht" : "Speichern & fortfahren"}
              onButtonClick={onNextClick}
              disabled={loading || nextBtnDisabled}
              style={{width: '100%'}}
              customClasses={{ root: classes.panelButton }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NavigationButtons;
import {parseResponse, PortfolioHandlerResource} from "../../../utils/api";
import React, {useState} from "react";
import _ from "lodash";
import {AGGREGATED_PORTFOLIO_ID, getErrorMessage} from "../../../utils/utils";

const defaultData = {
  expectedShortfall: null,
  pf_sharpe_ratio: null,
  tracking_error: null,
  information_ratio: null,
  alpha: null,
  beta: null,
  bm_sharpe_ratio: null
}

export function useKeyIndicatorsData(customerId, portfolios, benchmarks, withHistoriaclPortfolios, startDate, endDate, dataProvider=PortfolioHandlerResource,  assets, investmentStrategyId) {
  const [data, setData] = useState({
    data: defaultData,
    errors: null,
    loading: false,
    updatedAt: +new Date()
  });

  async function fetch() {

    setData({
      data: defaultData,
      errors: null,
      loading: true,
      updatedAt: +new Date()
    });

    try {

      const depotNumbers = portfolios.map((p) => p.depotNumber);

      const riskResponse = await dataProvider.getRiskData(
        customerId, depotNumbers, startDate, endDate,
        withHistoriaclPortfolios, benchmarks, assets, investmentStrategyId);

      const keyIndicatorsResponse = await dataProvider.getKeyIndicatorsData(
        customerId, AGGREGATED_PORTFOLIO_ID,  startDate, endDate,
        benchmarks, assets, investmentStrategyId, depotNumbers);

      let expectedShortfall = null,
        pf_sharpe_ratio = null,
        tracking_error = null,
        bm_sharpe_ratio = null,
        information_ratio = null,
        alpha = null,
        beta = null,
        error = null;

      parseResponse(riskResponse, 'risk',
        data => {
          if (_.has(data, 'volatility.min_date_message')) {
            error = data['volatility']['min_date_message'];
          } else {
            expectedShortfall = _.get(data, 'volatility.risk_metrics.cvar_hist_hp');
          }
        },
        error => {
          error = error;
        }
      );

      parseResponse(keyIndicatorsResponse, 'indicators_sharpe_ratio',
        data => {
          pf_sharpe_ratio = _.get(data, 'indicators.sharpe_ratio_a') || null;
          tracking_error = (_.get(data, 'indicators.tracking_error') * 100) || null;
          alpha = _.get(data, 'indicators.alpha') || null;
          beta = _.get(data, 'indicators.beta') || null;
          information_ratio = _.get(data, 'indicators.information_ratio') || null;
        },
        error => {
          error = error;
        }
      )

      parseResponse(keyIndicatorsResponse, 'indicators_sharpe_ratio_bm',
        data => {
          bm_sharpe_ratio = _.get(data, 'indicators.sharpe_ratio_a') || null;
        },
        error => {
          error = error
        }
      )

      setData({
        data: {
          expectedShortfall,
          pf_sharpe_ratio,
          tracking_error,
          alpha,
          beta,
          information_ratio,
          bm_sharpe_ratio
        },
        errors: error,
        loading: false,
        updatedAt: +new Date()
      });

    } catch (errors) {
      setData({
        data: defaultData,
        errors: getErrorMessage(errors),
        loading: false,
        updatedAt: +new Date()
      });
    }
  }

  return [data, fetch];
}
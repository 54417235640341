import React from 'react';
import { connect } from 'react-redux';
import {UserUtils} from '../../utils/utils';
import setSticky from '../../utils/sticky';

/* Material UI Components*/
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import withWidth from "@material-ui/core/withWidth/withWidth";
import { MuiThemeProvider } from '@material-ui/core/styles';

/* BCA Components */
import ErrorModal from '../../components/ErrorModal';
import Navigation from './components/PortfolioNavigation';
import InvestmentDetailsNavigation, {
  DashboardPortfoliosSelectorSharedState,
  StickyNavigation
} from '../CustomerDashboard/components/InvestmentDetailsNavigation';
import Widgets from '../CustomerDashboard/components/Widgets';
import withDashboardData from '../../components/DashboardDataProvider';
import ProfileDetails from '../CustomerDashboard/ProfileDetails';
import TotalReturn from '../../components/InvestmentDetails/components/TotalReturn';
import { GuideTour } from '../../components/GuideTour';
import {
  CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE,
  PERMISSION_ERROR_MODAL_MESSAGE,
  SELECTED_LONGER_TIMEFRAME
} from '../../utils/constants'

/* BCA modules */
import {
  getFromStorage,
  SHARED_SETTINGS_KEY
} from '../../utils/storage';
import { displayWarningSnackBar } from '../../components/SnackbarProvider/actions'
import defaultTheme from '../../themes/mainTheme'
import {getErrorMessage, getReportGenerationSettings} from "../../utils/utils";
import {REPORT_TYPES} from "../DashboardSettings/components/CustomersList/components/ReportType/constants";
import {downloadPdf} from "../../utils/utils";

import useStyles from './styles';
import {getGuideSteps} from "./guide";
import _ from "lodash";
import DownloadPdfDialog from "../../components/DownloadPdfModal/DownloadPdfModal";

const mapStateToProps = (state) => ({
  guide: state.get('guide').toJS(),
  auth: state.get('auth').toJS(),
});


export default connect(mapStateToProps)(withDashboardData(withWidth()((props) => {

  const {
    customer,
    auth,
    portfolios,
    investmentData,
    dashboardData,
    instrumentList,
    historicalData,
    profitAndLoss,
    profitAndLossSummary,
    paymentPlans,
    payoutPlansData,
    switchPlansData,
    width,
    unrealizedProfitAndLoss,
    updateHistoricalData,
    onSelectedPortfoliosChanged,
    onSelectedDatesChanged,
    selectedPortfolios,
    selectedDates,
    selectedDatesType,
    breakdownData,
    timeWeightedReturnData,
    riskData,
    isVirtual,
    dataLoading,
    selectedRange,
    chartsSettings,
    handleChartSettingsChange,
    reportType
  } = props;

  const classes = useStyles();

  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [expandedItems, setExpandedItems] = React.useState({
    historicalChart: true,
    performanceLineChart: true,
    performanceBarChart: true,
    riskChart: true,
    structureChart: true,
    profitAndLossItems: [],
    profitAndLossSubItems: {},
    paymentPlansItems: [],
    payoutPlansItems: [],
    switchPlansItems: [],
    instrumentsItems: [],
    instrumentsSubItems: {},

    cumulativeReturnChart: true,
    forecastChart: true,
    performanceTable: true,
    singlePerformance: true,

    // charts KeyFiguresTab
    keyIndicatorsChart: true,
    rollingVolatilityChart: true,
    rollingSharpeRatioChart: true,
    esgScoreChart: true,
    // charts from RiskAnalysisTab
    riskReturnChart: true,
    stressTestChart: true,
    correlationMatrix: true
  });
  const [pdfExportBtnDisabled, setPdfExportBtnDisabled] = React.useState(false);
  const [pdfOpen, setPdfOpen] = React.useState(false);

  React.useEffect(() => {
    setSticky('app-main', 'sticky-nav-stoper', 'investment-navigation-component-sticky', 'sticky-trigger')
  }, []);

  React.useEffect(() => {
    if (!props.auth.error) {
      if (investmentData.errors) {
        setErrorMessage(investmentData.errors)
      }

      if (customer.errors && customer.errors.status === 401) {
        return;
      } else if (customer.errors && customer.errors.status === 404) {
        setErrorMessage(CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE)
      } else if (customer.errors && customer.errors.status === 403) {
        setErrorMessage(PERMISSION_ERROR_MODAL_MESSAGE)
      } else {
        setErrorMessage(customer.errors)
      }

      if (getFromStorage(SHARED_SETTINGS_KEY)) {
        let appSettings = getFromStorage(SHARED_SETTINGS_KEY).app_settings || {}
        if (selectedRange.isBig && appSettings.calculation_warning_enabled) {
          props.dispatch(displayWarningSnackBar(SELECTED_LONGER_TIMEFRAME))
        }
      }
    }
  }, [
    historicalData.errors,customer.errors,
    dashboardData.errors,
    investmentData.errors,
    instrumentList.errors,
    selectedRange.id
  ]);

  const handleErrorModalClose = () => {
    setErrorMessage(undefined);
  };

  const handleExpandedItemsChange = (field, data) => {
    setExpandedItems(prevState => { return { ...prevState, [field]: data }});
  };

  const fetchPdfFile = async (skip_expanded, skip_sub_depot_expanded, editorEnabled, editorValue, extraFiles) => {
    setPdfExportBtnDisabled(true);
    setPdfOpen(false);

    try {
      const settings = _.get(customer, 'data.dashboard_settings', {});

      let report_type = _.get(settings, 'report_type', REPORT_TYPES.BASIC.value);
      let include_historical = !!_.get(settings, 'include_historical_portfolios');

      let formData = getReportGenerationSettings(customer.data && customer.data.customer_id, selectedPortfolios, portfolios,
        report_type, selectedDates, skip_expanded, skip_sub_depot_expanded, expandedItems, false, undefined, include_historical, [], chartsSettings)
      formData.append('customer_report', true)


      await downloadPdf('reports/report/ad-hoc/', undefined, 'POST', undefined, formData)

    } catch (errors) {
      if(_.isArray(_.get(errors, 'data'))){
        // get uniq error messages and concat them to single string
        errors = Object.values(errors.data) // get list of each file errors objects
        errors =errors.map(e => _.get(e, 'file', _.isString(e) ? e : '')) // getting only error messages
        errors = _.flatten(errors) // making one plain list with all errors
        errors = [...new Set(errors)].join('\n') // joining uniq messages in one error message
      }
     setErrorMessage(getErrorMessage(errors))
    }

    setPdfExportBtnDisabled(false)
  };

  const withOtherAssets =  _.get(customer, 'data.dashboard_settings.with_other_assets', true);

  return (
    <React.Fragment>
        <GuideTour
          steps={getGuideSteps(width, UserUtils.getCustomerReportType(customer.data), withOtherAssets)}
          title="Vermögensübersicht Info-Tour"
        />
        <Container className={`app-page-container`}>
          <Navigation
            handlePdfExportClick={() => {setPdfOpen(true)}}
            pdfExportDisabled={_.isEmpty(selectedPortfolios) || pdfExportBtnDisabled}
            loading={pdfExportBtnDisabled}
          />
          <ProfileDetails
            customer={customer.data}
            customerLoadingError={customer.errors}
            customerDataLoading={customer.loading}
            investmentData={investmentData.data}
            investmentDataLoading={investmentData.loading}
            investmentDataLoadingError={investmentData.errors}
            unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
            unrealizedProfitAndLossDataLoading={unrealizedProfitAndLoss.loading}
            unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLoss.errors}
            withOtherAssets={withOtherAssets}
            reportType={reportType}
          />
          <DashboardPortfoliosSelectorSharedState>
            <StickyNavigation
              calculationDates={selectedDates}
              calculationDatesType={selectedDatesType}
              handleCalculationDatesChanged={onSelectedDatesChanged}
              portfolios={portfolios.data}
              portfoliosLoadingError={portfolios.errors}
              portfoliosDataLoading={portfolios.loading}
              handleSelectedPortfolioChanged={onSelectedPortfoliosChanged}
              selectedPortfolios={selectedPortfolios}
              dataLoading={dataLoading}
              investmentData={investmentData.data}
              isCustomerApp
            />
            <Grid container style={{marginBottom: 20}}>
              <Grid item className={classes.investmentNavigationContainer} id="sticky-trigger">
                <InvestmentDetailsNavigation
                  calculationDates={selectedDates}
                  calculationDatesType={selectedDatesType}
                  handleCalculationDatesChanged={onSelectedDatesChanged}
                  updateHistoricalData={updateHistoricalData}
                  portfolios={portfolios.data}
                  portfoliosLoadingError={portfolios.errors}
                  portfoliosDataLoading={portfolios.loading}
                  handleSelectedPortfolioChanged={onSelectedPortfoliosChanged}
                  selectedPortfolios={selectedPortfolios}
                  investmentData={investmentData.data}
                  isVirtual={isVirtual}
                  dataLoading={dataLoading}
                  isCustomerApp
                />

              </Grid>
              <Grid item className={classes.totalReturnContainer}>
                <div className={classes.totalReturnCard}>
                  <TotalReturn
                    data={investmentData.data}
                    loading={investmentData.loading}
                    styles={{
                      header: {
                        fontSize: 18
                      }
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </DashboardPortfoliosSelectorSharedState>
          <MuiThemeProvider theme={defaultTheme}>
            <Widgets
              dashboardSettings={_.get(customer, 'data.dashboard_settings', {})}
              selectedPortfolios={selectedPortfolios}
              calculationDates={selectedDates}
              dashboardData={dashboardData.data}
              dashboardDataLoading={dashboardData.loading}
              dashboardDataLoadingError={dashboardData.errors}
              instrumentListData={instrumentList.data}
              instrumentListDataLoading={instrumentList.loading}
              instrumentListDataLoadingError={instrumentList.errors}
              historicalData={historicalData}
              profitAndLossData={profitAndLoss}
              profitAndLossSummary={profitAndLossSummary.data}
              paymentPlansData={paymentPlans.data}
              paymentPlansLoading={paymentPlans.loading}
              paymentPlansLoadingError={paymentPlans.errors}
              payoutPlansData={payoutPlansData.data}
              payoutPlansLoading={payoutPlansData.loading}
              payoutPlansLoadingError={payoutPlansData.errors}
              switchPlansData={switchPlansData.data}
              switchPlansLoading={switchPlansData.loading}
              switchPlansLoadingError={switchPlansData.errors}
              unrealizedProfitAndLossData={unrealizedProfitAndLoss.data}
              unrealizedProfitAndLossDataLoading={unrealizedProfitAndLoss.loading}
              unrealizedProfitAndLossDataLoadingError={unrealizedProfitAndLoss.errors}
              isCustomerApp
              breakdownData={breakdownData}
              timeWeightedReturnData={timeWeightedReturnData}
              riskData={riskData}
              expandedItems={expandedItems}
              chartsSettings={chartsSettings}
              onExpandedItemsChange={handleExpandedItemsChange}
              onChartSettingsChange={handleChartSettingsChange}
              virtualInstrumentLink={customer.data && customer.data.user.agency.virtual_instrument_buy_template}
              reportType={reportType}
            />
          </MuiThemeProvider>
        </Container>
      <ErrorModal message={errorMessage} handleClose={handleErrorModalClose}/>
      <DownloadPdfDialog
        open={pdfOpen}
        onClose={() => {setPdfOpen(false)}}
        onExport={fetchPdfFile}
        exportBtnDisabled={!selectedPortfolios || pdfExportBtnDisabled}
        title={'Vermögensübersicht drucken'}
      >
        <DownloadPdfDialog.Expanded />
      </DownloadPdfDialog>
    </React.Fragment>
  )
})))
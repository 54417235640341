import FileDownload from "js-file-download";

import {getFromStorage, TOKEN_KEY} from "../../utils/storage";
import {getFileNameFromResponse} from "../../utils/utils";
import {DISTRIBUTION_TYPES} from "./constants";
import {axiosInstance} from "../../utils/api";
import _ from 'lodash';

function getDistributonType (reportSettings) {
  return reportSettings.is_auto_report ? DISTRIBUTION_TYPES.BRIEF.value: reportSettings.distribution_type
}

export class SendReportUtils {
  static url = '/reports/broker/series/';
  static groupUrl = '/reports/broker/groups/';

  static createCustomerReport (reportSettings, selectedDepots, newDesign) {
    const distribution_type = getDistributonType(reportSettings);
    const params = {
      customer_id: reportSettings.customer_id,
      message: reportSettings.cover_message_enabled ? reportSettings.cover_message_content : '',
      depots: selectedDepots,
      is_auto_report: reportSettings.is_auto_report,
      distribution_type: distribution_type,
      new_design: newDesign
    };
    return SendReportUtils.createReport(SendReportUtils.url, params, distribution_type);
  }

  static createGroupReport (group, newDesign) {
    const distribution_type = getDistributonType(group);
    const params = {
      group_id: group.id,
      // message: group.cover_message_enabled ? group.cover_message_content : '' TODO if message is in settings in back-end there is no need to pass it
      is_auto_report: _.get(group, 'is_auto_report', false),
      distribution_type: distribution_type,
      new_design: newDesign
    };
    return SendReportUtils.createReport(SendReportUtils.groupUrl, params, distribution_type);
  }

  static createReport (url, params, distribution_type) {
    const config = SendReportUtils.getDownloadRequestConfig();

    return new Promise((resolve, reject) => {
      axiosInstance.post(url, params, config)
      .then((response) => {
        const filename = getFileNameFromResponse(response);
        if (filename) {
          FileDownload(response.data, filename, 'application/pdf');
        }
        resolve(SendReportUtils.getSuccessMessageByDistributionTypes(distribution_type));
      })
      .catch(() => {
        const errorMessage = SendReportUtils.getErrorMessageByDistributionType(distribution_type);
        reject(errorMessage);
      });
    });
  }

  static getDownloadRequestConfig() {
    let config = {
      responseType: 'arraybuffer'
    };

    let token = getFromStorage(TOKEN_KEY);
    if (token) {
      config.headers = {
        'Authorization': `Bearer ${token}`
      }
    }
    return config;
  }

  static getSuccessMessageByDistributionTypes(type) {
    if (type === DISTRIBUTION_TYPES.POSTBOX.value) {
      return 'Die erzeugten Dokumente werden in das elektronische Kundenpostfach der DIVA eingestellt.\n' +
        'Sie können den Fortschritt der Zustellung der Anzeige im Downloadbereich am oberen Bildschirmrand einsehen.\n' +
        'Nach Beendigung des Vorgangs können Sie dort eine Status Übersicht herunterladen.';
    }
    return 'Ihre Serienreports werden aktuell erstellt. Sie können den Fortschritt im Downloadbereich des oberen Bildschirmendes entnehmen.';
  }

  static getErrorMessageByDistributionType(type) {
    if (type === DISTRIBUTION_TYPES.POSTBOX.value) {
      return 'Bei der Versendung Ihrer Reports ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';
    }
    return 'Bei der Erstellung Ihrer PDFs ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';
  }
}

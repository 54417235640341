import _  from 'lodash';
import React from "react";
import FileDownload from 'js-file-download';
import {parsePhoneNumber} from "react-phone-number-input/input-max";

import {
  ALLOCATION_REGIONS,
  MAX_LIGHTENING_INDEX,
  LIGHTENING_INDEX_PERCENT,
  MATURITY_MAP,
  CREDIT_QUALITY_MAP,
  FIXED_INCOME_SUB_TYPE_MAP,
  OTHER_TYPE_TRANSLATION, DATE_FORMAT
} from "./constants";
import { RISK_FILTERING_TYPE } from '../components/FilteringPanel/components/RiskFilter/constants';
import HTMLHostRelatedSharedSetting from '../components/HTMLHostRelatedSharedSetting'
import * as REPORTING_FILTER_TYPES from "../components/FilteringPanel/components/ListSelector/constants";
import {REPORT_TYPES} from "../containers/DashboardSettings/components/CustomersList/components/ReportType/constants";
import {toGermanFormat} from "./numberFormater";
import {getFromStorage, SHARED_SETTINGS_KEY, TOKEN_KEY} from "./storage";
import {axiosInstance, QuestionnairesHandlerResource} from './api';
import {buildFactSheetsLink} from "../routes";
import {OBJECT_TYPES} from '../containers/RiskProfiling/constants'
import moment from "moment";
import WarningTooltip from "../components/WarningTooltip";
import {getInvestmentDynamicPath} from "../containers/InvestmentPlatform/utils";
import {AGGREGATED_ASSET_NAME, AGGREGATED_PORTFOLIO_NAME, formatPortfolioName} from "./aggregated-portfolio";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import { saveAs } from 'file-saver';
import momentBusinessDays from "moment-business-days";


export const AGGREGATED_PORTFOLIO_ID = 0;


export const getCurrentUrlDynamicInvestmentAppPath = () => {
  let currentPath = window.location.pathname;
  let currentPathParts = currentPath.split('/');
  return currentPathParts[1];
};

export const newDesignUsed = () => {
  const investmentDynamicPath = getInvestmentDynamicPath();
  let currentPath = getCurrentUrlDynamicInvestmentAppPath();
  return currentPath === investmentDynamicPath;
}


export class PortfolioUtils {

  static isPortfoliosEquals(array$1, array$2) {

    const _getPortfolioDepotNumber = (portfolio) => portfolio.depotNumber;

    if (array$1.length !== array$2.length)
      return false;

    const array$1DepotNumbers = array$1.map(_getPortfolioDepotNumber);
    const array$2DepotNumbers = array$2.map(_getPortfolioDepotNumber);

    for (let i = 0; i < array$1DepotNumbers.length; i++) {
      if (array$2DepotNumbers.indexOf(array$1DepotNumbers[i]) === -1)
        return false;
    }

    return true;
  };

  static getSelectedPortfoliosNumbers(portfolios) {
    let allPortfolios = _.find(portfolios, (portfolio) => {
      return portfolio.depotNumber === 0;
    });

    if (allPortfolios) {
      return  [0];
    }

    return portfolios.map(portfolio => portfolio.depotNumber);
  }

  static _getPortfolioBreakDown(portfolioStructure, breakDownKey) {
    if (portfolioStructure[breakDownKey]) {
      return portfolioStructure[breakDownKey].map(item => ({
        ...item,
        name: item.name_de || _.get(OTHER_TYPE_TRANSLATION, item.name, item.name)
      }))
    }
    return [];
  }

  /**
   * Get portfolios asset classes
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios asset classes
   */
  static getPortfolioBroadAssetClasses(portfolioStructure) {
    return this._getPortfolioBreakDown(portfolioStructure, 'by_broad_asset_class')
  }

  /**
   * Get portfolios currencies
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios currencies
   */
  static getPortfolioCurrencies(portfolioStructure) {
    return this._getPortfolioBreakDown(portfolioStructure, 'by_currency');
  }


  /**
   * Get portfolios SRRIs
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios SRRIs
   */
   static getPortfolioRiskIndicators(portfolioStructure) {
    if (portfolioStructure.by_srri) {
      return portfolioStructure.by_srri.map(srri => ({
        ...srri,
        name: _.get(OTHER_TYPE_TRANSLATION, srri.name, `SRI ${srri.name}`)
      }))
    }
    return [];
  }

  /**
   * Get portfolios Risk indicators
   *
   * @param {Object} portfolioStructure Portfolios structure data
   * @param {Object} portfolios Portfolios investment data
   *
   * @returns {Array} Portfolios Risk Indicators
   */
  static getPortfolioRiskIndicatorsColored(portfolioStructure, portfolios) {
    if (portfolioStructure.by_risk_indicators) {
      return portfolioStructure.by_risk_indicators.map((srri) => {
        let number_of_instruments = 0;
        portfolios.forEach((portfolio) => {
          // if by_risk_indicator does not contain srri -> skip to prevent crash
          if (!_.get(portfolio, `by_risk_indicator['${srri.name}'].components`)) { return; }

          number_of_instruments += portfolio.by_risk_indicator[srri.name].components.length
        })
        return {
          color: srri.color,
          market_value: srri.market_value,
          weight: srri.weight,
          name: `${srri.name} (${number_of_instruments} ${number_of_instruments > 1 ? 'Positionen' : 'Position'})`,
        }
      })
    }
    return [];
  }

  /**
   * Get portfolios sectors
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios sectors
   */
  static getPortfolioSectors(portfolioStructure) {
    return this._getPortfolioBreakDown(portfolioStructure, 'by_sector');
  }

  /**
   * Get portfolios maturity
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios maturity
   */
  static getPortfolioMaturity(portfolioStructure) {
    if (portfolioStructure.by_maturity) {
      return portfolioStructure.by_maturity.map(maturity => ({
        ...maturity,
        name: maturity.name && MATURITY_MAP[maturity.name] ? MATURITY_MAP[maturity.name] : maturity.name
      }))
    }
    return [];
  }

  /**
   * Get portfolios credit quality
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios credit quality
   */
  static getPortfolioCreditQuality(portfolioStructure) {
    if (portfolioStructure.by_credit_quality) {
      return portfolioStructure.by_credit_quality.map(credit => ({
        ...credit,
        name: credit.name && CREDIT_QUALITY_MAP[credit.name] ? CREDIT_QUALITY_MAP[credit.name] : credit.name
      }))
    }
    return [];
  }

  /**
   * Get portfolios fixed income sub types
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios fixed income sub types
   */
  static getPortfolioFixedIncomeSubType(portfolioStructure) {
    if (portfolioStructure.by_fixed_income_sub_type) {
      return portfolioStructure.by_fixed_income_sub_type.map(fixed_income => ({
        ...fixed_income,
        name: fixed_income.name && FIXED_INCOME_SUB_TYPE_MAP[fixed_income.name] ? FIXED_INCOME_SUB_TYPE_MAP[fixed_income.name] : fixed_income.name
      }))
    }
    return [];
  }

  /**
   * Get portfolios morningstar categories
   *
   * @param {Object} portfolioStructure Portfolios structure data
   *
   * @returns {Array} Portfolios morningstar categories
   */
  static getPortfolioMorningstarCategories(portfolioStructure) {
    return this._getPortfolioBreakDown(portfolioStructure, 'by_ms_category')
  }

  static getPortfolioAssetAllocations(portfolioStructure) {
    return this._getPortfolioBreakDown(portfolioStructure, 'by_type')
  }


  /**
   * Get portfolios regions
   *
   * @param {Object} portfoliosStructure Portfolios structure data
   *
   * @returns {Array} Portfolios regions
   */
  static getPortfolioRegions(portfoliosStructure) {
    if (portfoliosStructure.by_region) {
      return portfoliosStructure.by_region.map(region => ({
        market_value: region.market_value,
        weight: region.weight,
        code: ALLOCATION_REGIONS[region.name].code,
        name: ALLOCATION_REGIONS[region.name].name,
      }));
    }
    return [];
  }
}

export function getColorByValue(value) {
  const _value = Number(value)
  return _value > 0 ? '#45a94f' : _value < 0 ? '#ec4056' : 'inherit';
}

export function shadeColorByIndex(index, color) {
  const lighteningIndex = getLighteningIndex(index);
  const percent = lighteningIndex * LIGHTENING_INDEX_PERCENT;
  return lighten(color, percent);
}

export function shadeColorByCount(count, color) {
  return _.times(count).map((item, index) => {
    return shadeColorByIndex(index, color);
  });
}

function getLighteningIndex(i) {
  return i <= MAX_LIGHTENING_INDEX ? i : i % MAX_LIGHTENING_INDEX
}

function addLight(color, amount){
  let cc = parseInt(color,16) + amount;
  let c = (cc > 255) ? 255 : (cc);
  c = (c.toString(16).length > 1 ) ? c.toString(16) : `0${c.toString(16)}`;
  return c;
}

function lighten(color, amount){
  color = (color.indexOf("#")>=0) ? color.substring(1,color.length) : color;
  amount = parseInt((255*amount)/100);
  return color = `#${addLight(color.substring(0,2), amount)}${addLight(color.substring(2,4), amount)}${addLight(color.substring(4,6), amount)}`;
}

export const filterCustomers = function *(customers, filters) {

  for(let i = 0; i < customers.length; i++) {
    let customer = customers[i];

    if (filters) {
      let isAcceptable = true;
      const searchString = filters.search && filters.search.trim().toLowerCase() || '';
      if (searchString.length > 0) {
        isAcceptable = isSearchStringInCustomerName(customer, searchString) || isSearchStringInCustomerId(customer, searchString);
      }

      if (filters.aggregatted) {
        if (parseFloat(customer.aggregated_value) < filters.aggregatted[0] || parseFloat(customer.aggregated_value) > filters.aggregatted[1]) {
          isAcceptable = false;
        }
      }

      if (filters.riskType) {
        if (!isAcceptableByRiskType(customer, filters.riskType)) {
          isAcceptable = false;
        }
      }

      if (isAcceptable) {
        yield customer;
      }

    } else {
      yield customer;
    }
  }
}

export const paginateArray = function *(array, recordPerPage) {
  let deepArray = [...array];

  while (deepArray.length >= recordPerPage) {
    yield deepArray.splice(0, recordPerPage);
  }

  if (deepArray.length > 0) {
    yield deepArray;
  }
}

export const isSubDepotItem = (item) => item.isSubItem && !item.is_completely_sold;

/**
 * Paginator for cases when sub items should not be taken into account.
 * Example: Switch-In transactions
 * @param array
 * @param recordPerPage
 * @returns {Generator<*[], void, *>}
 */
export const paginateSubItemsArray = function *(array, recordPerPage) {
  let result = [];
  let counter = 0;

  for (let item of array) {
    if (counter < recordPerPage || item.isSubItem) {
      result.push(item);
      if (!item.isSubItem) {
        counter++;
      }
    } else {
      yield result;
      result = [item];
      counter = 1;
    }
  }

  if (result.length > 0) {
    yield result;
  }
}

/**
 * Find max aggregatted value
 *
 * @param {Array<Object>} customers
 *
 * @returns {Number}
 */
export const findMaxAggregattedValue = (customers) => {
  return _.maxBy(customers, (customer) => parseFloat(customer.aggregated_value) || 0);
}

/**
 * Check, if customer belongs to risk type
 *
 * @param {Object} customer
 * @param {Object} isAcceptableByRiskType
 *
 * @returns {Boolean}
 */
export const isAcceptableByRiskType = (customer, type) => {
  let difference = customer.product_risk - customer.client_risk;

  if (type === RISK_FILTERING_TYPE.ALL) {
    return true;
  }

  if (difference >= 3) {
    return type === RISK_FILTERING_TYPE.RED;
  } else if (difference >= 1) {
    return type === RISK_FILTERING_TYPE.YELLOW;
  } else if (difference <= 0) {
    return type === RISK_FILTERING_TYPE.GREEN;
  }
}


function fullNameCustomerSorter(customer) {
    return customer.customer_full_name && customer.customer_full_name.toLowerCase() || '';
}

export const reportSortCustomers = (customers, sortingType = REPORTING_FILTER_TYPES.SORTING_TYPES.NAME_ASC.value) => {
  switch (sortingType) {
    // case REPORTING_FILTER_TYPES.SORTING_TYPES.AUM_DESC.value:
    //     return _.orderBy(customers, ['total'], ['desc']);
    // case REPORTING_FILTER_TYPES.SORTING_TYPES.AUM_ASC.value:
    //     return _.orderBy(customers, ['total'], ['asc']);
    case REPORTING_FILTER_TYPES.SORTING_TYPES.NAME_ASC.value:
      return _.orderBy(customers, [fullNameCustomerSorter], ['asc']);
    case REPORTING_FILTER_TYPES.SORTING_TYPES.NAME_DESC.value:
      return _.orderBy(customers, [fullNameCustomerSorter], ['desc']);
  }
};


export const withEuroOrDash = (number, fraction=2, lose_decimals=false) => {
  return number || number === 0 ? toGermanFormat(number, '', ' €', fraction, lose_decimals) : '-'
};

export const QTY_DECIMALS = 4;
export const VIRTUAL_PORTFOLIO_QTY_DECIMALS = 6;

export const formatQty = (number, fraction=QTY_DECIMALS, lose_decimals=true, min_decimals=2) => {
  const value = parseFloat(number);
  return _.isNaN(value) ? '-' : toGermanFormat(number, '', '', fraction, lose_decimals, min_decimals);
};

export const getErrorMessage = (error) => {
  if (!_.isNil(error)) {

    if (_.isObject(error)) {
      return error.detail || error.message || _.get(error, 'data.detail') || error.statusText;
    } else if (_.isString(error)) {
      return error
    }
  }
};

export const withPercentOrDash = (number, isWeight=true) => {
  const suffix = ' %';
  const isNumber = typeof number === 'number';
  return isNumber ? toGermanFormat(isWeight ? number * 100 : number, '', suffix) : '-'
};

export const valueOrDash = (number) => {
  const value = parseFloat(number);
  return _.isNaN(value) ? '-' : toGermanFormat(value);
};

export const valueOrDashInt = (number) => {
  const value = parseInt(number);
  return _.isNaN(value) ? '-' : value;
};

export const formatDateOrDash = (date, dateFormat='DD.MM.YYYY') => {
  return _.isNil(date) ? '-' : moment(date).format(dateFormat);
};

export const getCountWithName = (customers, searchString) => {

  return _.countBy(customers, customer => {
    return isSearchStringInCustomerName(customer, searchString) || isSearchStringInCustomerId(customer, searchString);
  })
};

const isSearchStringInCustomerId = (customer, searchString) => {
  return customer.customer_id.includes(searchString)
};

const isSearchStringInCustomerName = (customer, searchString) => {
  return customer.customer_first_name.toLowerCase().includes(searchString.toLowerCase())
    || customer.customer_last_name.toLowerCase().includes(searchString.toLowerCase())
    || `${customer.customer_first_name.toLowerCase()} ${customer.customer_last_name.toLowerCase()}`.includes(searchString.toLowerCase())
    || `${customer.customer_last_name.toLowerCase()} ${customer.customer_first_name.toLowerCase()}`.includes(searchString.toLowerCase())
    || customer.customer_full_name.toLowerCase().includes(searchString.toLowerCase())
};

export const getFileNameFromResponse = (response, withDecode=false) => {
  if (response.headers['content-disposition'] && response.headers['content-disposition'].indexOf('attachment') !== -1) {
    let fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    let matches = fileNameRegex.exec(response.headers['content-disposition']);
    if (matches != null && matches[1]) {
      let filename = matches[1].replace(/['"]/g, '');
      if (withDecode) return decodeURI(filename)
      return filename;
    }
  }
  return false;
}


export const hasResponseError = (obj) => {
  return obj && (obj.hasOwnProperty('error') || obj.hasOwnProperty('errors'));
};

export const hasFilterResult = (filterResult) => {
  return filterResult && typeof filterResult.word !== 'undefined';
};


const BCA_BETA_GROUP_NAME = 'BCA_BETA'


export class UserUtils {

  static isBetaUser(authState) {

    if (authState && authState.user && authState.user.groups) {
      return !! _.find(authState.user.groups, group => group.name == BCA_BETA_GROUP_NAME);
    }

    return false;
  }

  static isAuthorized(authState) {
    return authState && authState.user;
  }

  static isBroker(authState) {
    return authState && authState.user && authState.user.is_broker;
  }

  static isCustomer(authState) {
    return authState && authState.user && authState.user.is_customer;
  }

  static hasVirtualPortfolioAccess(authState) {
    return authState && authState.user && authState.user.virtual_portfolios_enabled;
  }

  static hasAgencyWebsite(authState) {
    return authState && authState.user && authState.user.agency && authState.user.agency.website
  }

  static isAgencyValid(authState, agency_id) {
    return authState && authState.user && authState.user.agency && authState.user.agency.agency_id === agency_id;
  }

  static getFullName(user) {
    return `${user.first_name} ${user.last_name}`
  }

  static getCustomerReportType(customer_data) {

    try {
      return customer_data.dashboard_settings.report_type;

    } catch (e) {
      // console.error(`Unable to get report type for customer`)
      return REPORT_TYPES.BASIC.value
    }
  }

  static isChief(authState) {
    return authState && authState.user && authState.user.is_chief || false;
  }
}

export const openMorningStartIsin = async (isin) => {
  window.open(buildFactSheetsLink(isin));
};

export const openFundShopInfosIsin = async (isin, link) => {
  // build and redirect by agency link template

  window.open(link.replace('{ISIN}', isin));

};

export const isIOS  = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export const downloadZip = (url, fileName, method='GET', getParams=undefined, postParams=undefined) => {
  const config = {
    responseType: 'arraybuffer'
  };

  const token = getFromStorage(TOKEN_KEY);
  if (token) {
    config.headers = {
      'Authorization': `Bearer ${token}`
    }
  }

  if (getParams) {
    config.params = getParams
  }

  const safariWindowRef = isIOS() ? window.open("about:blank","_blank") : null;

  return new Promise((resolve, reject) => {

    let request = undefined;

    if (method == 'GET') {
      request = axiosInstance.get(url, config);
    } else {
      request = axiosInstance.post(url, postParams, config);
    }
    request.then((response) => {
      if (!fileName) {
        fileName = getFileNameFromResponse(response);
      }

      if (fileName) {

        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

        downloadFile(downloadUrl, fileName);

        resolve();
      }
    }).catch(reject);
  })
}

export const downloadFile = (downloadUrl, fileName) => {
  const link = document.createElement('a');

  link.href = downloadUrl;

  link.setAttribute('download', fileName); //any other extension

  document.body.appendChild(link);

  link.click();

  link.remove();
};

/**
 *
 * @param documents {List<{path: String, preffix?: String}>}
 * @param zipFilename
 * @param usePreffix Flag to extend document name with suffix from document.
 * @returns {Promise<void>}
 */
export const mergePDFsFromExternalResource = async (documents, zipFilename, usePreffix=false) => {

  const mainZip = new JSZip();

  for (let document of documents) {

    const url = document.path;

    // get file name from url. Everything after last '/' is file name. decode is used to convert '%20' to spaces
    let filename = decodeURIComponent(url.substring(url.lastIndexOf("/") + 1));
    // azure file name contains milliseconds after last space and before file extension -> remove it
    const preffix = (usePreffix && !!document.preffix)? `${document.preffix} ` : '';
    filename = preffix + filename.substring(0, filename.lastIndexOf(' ')) + filename.substring(filename.lastIndexOf('.'))

    const pathContent = await JSZipUtils.getBinaryContent(url)
    mainZip.file(filename, pathContent, {binary: true});
  }

  let content = await mainZip.generateAsync({type:"blob"});
  saveAs(content, zipFilename);
}

export const mergeArchiversFromExternalResource = async (urls, archiveName) => {

  const mainZip = new JSZip();

  for (let url of urls) {
    const pathContent = await JSZipUtils.getBinaryContent(url)
    await mainZip.loadAsync(pathContent)
  }

  let content = await mainZip.generateAsync({type:"blob"});
  const downloadUrl = window.URL.createObjectURL(content);
  downloadFile(downloadUrl, archiveName);
}

export const downloadStaticFile = (fileName) => {
  const downloadUrl = '/static/{fileName}'.replace('{fileName}', fileName);

  downloadFile(downloadUrl, fileName);
}

export const downloadPdf = (url, fileName, method='GET', getParams=undefined, postParams=undefined) => {

  const config = {
    responseType: 'arraybuffer'
  };

  const token = getFromStorage(TOKEN_KEY);
  if (token) {
    config.headers = {
      'Authorization': `Bearer ${token}`
    }
  }

  if (getParams) {
    config.params = getParams
  }

  const safariWindowRef = isIOS() ? window.open("about:blank","_blank") : null;

  return new Promise((resolve, reject) => {

    let request = undefined;

    if (method == 'GET') {
      request = axiosInstance.get(url, config);
    } else {
      request = axiosInstance.post(url, postParams, config);
    }
    request.then((response) => {
      if (!fileName) {
        fileName = getFileNameFromResponse(response);
      }

      if (fileName) {
        const blob = new Blob([response.data], {type: "application/pdf"});
        if (isIOS()) {
          const url = (window.webkitURL || window.URL).createObjectURL(blob);
          safariWindowRef.location = url;
          setTimeout(() => {
            (window.webkitURL || window.URL).revokeObjectURL(url);
          }, 100);
        } else {
          FileDownload(response.data, fileName, 'application/pdf');
        }
        resolve();
      }
    }).catch(reject);
  })


}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase().trim());
}

export function convertPhoneIfValid(phoneNumber) {
  const cleanPhone = phoneNumber.replaceAll(/[-/()\s]/g, "");  // remove following symbols: -, / , (), space
  // ignore international format number
  if(cleanPhone[0] !== '+'){
    const phone = parsePhoneNumber(cleanPhone, 'DE');
    if(phone && phone.country === 'DE' && phone.isValid()){
      phoneNumber = phone.formatInternational();
    }
  }

  return phoneNumber;
}

export class UnrealizedProfitLossUtils {

  static getValueByCode(list, code) {
    if (!list && !list.length) {
      return 0
    }

    for (let i = 0; i < list.length; i++) {
      if (list[i].code == code) {
        return list[i].value || 0
      }
    }

    return 0
  }

  static getOpenTransactionValue(data, inflowCode, outflowCode) {
    let inflowValue = this.getValueByCode(data.inflows, inflowCode)
    let outflowValue = this.getValueByCode(data.outflows, outflowCode)

    return Math.abs(inflowValue + outflowValue)
  }

  static getTransactionValue(data, key, code) {
    if (data && data.hasOwnProperty(key)) {
      let value = this.getValueByCode(data[key], code);

      return Math.abs(value)
    }

    return 0;
  }
}


/**
 * Open file in new tab.
 *
 * @param {*} blob FIle content, received from api (list of bytes).
 * */
export const openFileInNewTab = (blob) => {
  var newBlob = new File([blob], 'hahaha.pdf', {type: "application/pdf"})

  // IE doesn't allow using a blob object directly as link href
  // // instead it is necessary to use msSaveOrOpenBlob
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   window.navigator.msSaveOrOpenBlob(newBlob);
  //   return;
  // }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  var link = document.createElement('a');

  window.open(data)
  // link.href = data;
  // link.download="file.pdf";
  // link.click();
  // setTimeout(function(){
  //   // For Firefox it is necessary to delay revoking the ObjectURL
  //   window.URL.revokeObjectURL(data);
  // }, 100);
}

export const handleChartSizeOnWindowResize = (event) => {
  window.addEventListener('resize', () => {
    let chart = event.target
    setTimeout(() => {
      if (chart.container && chart.container.parentElement) {
        chart && chart.setSize && chart.setSize(chart.container.parentElement.clientWidth, chart.container.parentElement.clientHeight)
      }
    }, 0)
  })
}

export function isValidString(value) {
  return typeof value === 'string' && value.trim().length > 0;
}

export function extend_chart_with_explanation(content, sharedSettingKey){
  /**
  * Returns chart with explanation rendered as html
  * @param {object} content - content of the chart
  * @param {string} explanation - html text that will be rendered below the chart, contains extra info about the chart
  */

  return (
    <>
    {content}
    {sharedSettingKey && <div style={{marginTop: '1.5em'}}><HTMLHostRelatedSharedSetting sharedSettingKey={sharedSettingKey} /></div>}
    </>
  )
}

export function getPositiveOrZero(number){
  return Math.max(0, number)
}

export async function onboardingOnCustomersChange(customers, onboardingType=OBJECT_TYPES.ONBOARDING){
  if(customers && Array.isArray(customers) && customers.length){
    return await QuestionnairesHandlerResource.at('onboarding_list/').post({
      customers_ids: customers.map(c => c.id),
      onboarding_type: onboardingType
    });
  }
};

export function objectToQuery(obj){
  return Object.keys(obj).map(function(key) {
    return key + '=' + obj[key];
  }).join('&');
}

/**
 * Get list of objects sorted by filed.
 *
 * @param {Array<*>} objects List of objects
 * @param {string} sortingFiled field of object that will be used to sort
 * @param {Array} order defaults to ['asc'], set sorting order
 * @return {Array<*>} Sorted or empty list of objects
 */
 export const getSortedObjects = (objects, sortingFiled = 'value', order = ['asc']) => {
  try{
    return _.orderBy(objects, [obj => {
      let objSortingField = _.get(obj, sortingFiled, false)
      return _.isString(objSortingField)? objSortingField.toLowerCase() : objSortingField
    }], order)
  } catch (e) {
    return objects
  }
}

export function RenderStepLoadingMessage(step){
  return (
    <>
      {step && step.loading_msg &&
        <div style={{marginLeft:'2%'}}>{step.loading_msg}</div>
      }
    </>
  )
}

export const priceChartTitle = {
  aggregate: 'Maximaler Wertverlust der Gesamtdepots',
  multiple: 'Maximaler Wertverlust der gewählten Depots',
  single: 'Maximaler Wertverlust des gewählten Depots'
}

export const volatilityChartTitle = {
  aggregate: 'Schwankungsbreite der Gesamtdepots',
  multiple: 'Schwankungsbreite der gewählten Depots',
  single: 'Schwankungsbreite des gewählten Depots'
}

export const blueTitle = {
  aggregate: 'Gesamtdepots',
  multiple: 'gewählte Depots',
  single: 'gewähltes Depot'
}

export function getRiskChartTitleType(selectedPortfolios){
   /** Returns key to risk chart titles depending on selected portfolios amount */
   if(selectedPortfolios){
      let isAggregatedSelected = _.find(selectedPortfolios, portfolio => portfolio.depotNumber == 0);

      if (isAggregatedSelected){ return 'aggregate' }
      if (selectedPortfolios.length > 1){ return 'multiple' }
      return 'single'
    }
}

export function renderInfoIconWithLabel(text, label_text=undefined, label_styles={}, tooltip_width){
  return (
    <>
      {label_text &&
        <span style={label_styles}>
          {label_text}
        </span>
      }
      <WarningTooltip title={text} width={tooltip_width} interactive/>
    </>
  )
}

export function getPortfolioMinStartOfInvestment(listOfPortfolios){
   let portfoliosStartOfInvestmentDates = listOfPortfolios.map(({start_tracking_date})=>moment(start_tracking_date));
   return moment.min(portfoliosStartOfInvestmentDates)
}

export function renderPortfolioDropdownName(portfoliosToRender, allPortfolios, isVirtual, placeholder){

   if (_.isEmpty(allPortfolios)) {
     return placeholder || "";
   }

  /** Renders name of selected portfolios */
  if (portfoliosToRender.length === 0) {
    return "";  // no portfolios are selected
  }

  let aggregatedInSelected = _.find(portfoliosToRender, p => _.isObject(p) ? p.depotNumber == 0 : p == 0)

  if (aggregatedInSelected || (allPortfolios && portfoliosToRender.length === allPortfolios.length && allPortfolios.length > 1)){
    return AGGREGATED_PORTFOLIO_NAME  // all portfolios are selected
  }

  else if (portfoliosToRender.length > 1) {
    return `${portfoliosToRender.length} ausgewählte Depots`;  // selected more than 1 but not all
  }

  else {
    return formatPortfolioName(_.get(portfoliosToRender[0], 'name') || '1 ausgewähltes Depot');  // single portfolio is selected
  }
}

export const renderAssetsDropdownName = (assetsToRender, allAssets, useAggregatedName=true) => {
  /** Renders name of selected assets */
  if (assetsToRender.length === 0) {
    return "";  // no portfolios are selected
  }

  if (useAggregatedName) {
    let aggregatedInSelected = _.find(assetsToRender, p => _.isObject(p) ? p.id == 0 : p == 0)

    if (aggregatedInSelected || (allAssets && assetsToRender.length === allAssets.length && allAssets.length > 1)){
      return AGGREGATED_ASSET_NAME  // all portfolios are selected
    }
  }

  if (assetsToRender.length > 1) {
    return `${assetsToRender.length} ausgewählte Produkte`;  // selected more than 1 but not all
  }

  else {
    return formatPortfolioName(_.get(assetsToRender[0], 'name') || '1 ausgewähltes Produkt');  // single asset is selected
  }
}

export const getBaseReportGenerationSettings = (extra_files, selectedDates, skip_expanded, expandedItems, defaultExpanedItems) => {
  const formData = new FormData();
  extra_files.forEach(file=>{
    formData.append('extra_files', file);
  });
  if(selectedDates.start){
    formData.append('start_date', selectedDates.start && selectedDates.start.format('YYYY-MM-DD'))
  }
  if(selectedDates.end){
    formData.append('end_date', selectedDates.end && selectedDates.end.format('YYYY-MM-DD'))
  }
  formData.append('expanded_items', JSON.stringify(skip_expanded ? defaultExpanedItems || {} : expandedItems))

  return formData
}

export const getReportGenerationSettings = (customer_id, selectedPortfolios, portfolios, report_type, selectedDates, skip_expanded,
   skip_sub_depot_expanded, expandedItems, cover_text_enabled, cover_message_content, includeHistoricalPortfolios, extra_files, chartsSettings, instrumentsGroupBy, excludedPortfolios) => {
  const selectedDepots = selectedPortfolios.map(portfolio => String(portfolio.depotNumber));
  const customerPortfoliosCount = portfolios && portfolios.data && portfolios.data.length || 0;
  const depots = customerPortfoliosCount === 1 ? ['0'] : selectedDepots.includes('0') ? ['0'] : selectedDepots;
  const formData = getBaseReportGenerationSettings(extra_files, selectedDates, skip_expanded, expandedItems)

  formData.append('customer_id', customer_id)
  formData.append('report_type', report_type)
  formData.append('depots', JSON.stringify(depots))

  formData.append('expanded_sub_depot_items', JSON.stringify({
    ..._.get(expandedItems, 'instrumentsSubItems', {}),
    skip_expanded: skip_sub_depot_expanded
  }))

  formData.append('expanded_profit_loss_sub_depot_items', JSON.stringify({
    ..._.get(expandedItems, 'profitAndLossSubItems', {}),
    skip_expanded: skip_sub_depot_expanded
  }))

  formData.append('charts_settings', JSON.stringify(chartsSettings))
  formData.append('instruments_group_by', instrumentsGroupBy || 'instruments')
  formData.append('cover_text_enabled', cover_text_enabled)
  if(cover_message_content){
    formData.append('cover_message_content', cover_message_content)
  }
  if(customerPortfoliosCount === 1){
    formData.append('real_depot_id', JSON.stringify(selectedDepots))
  }
  if (excludedPortfolios) {
    formData.append('excluded_depots', JSON.stringify(excludedPortfolios.map(portfolio => String(portfolio.depotNumber))))
  }
  formData.append('with_historical_portfolios', includeHistoricalPortfolios)
  return formData
}

const checkIndividualSettings = (customSettings, fieldId) => !!_.get(customSettings, fieldId, {checked: true}).checked;

export const isSectionVisible = (customSettings, fieldId, reportType) => {
   // check if section is enabled in Custom type
  return reportType !== REPORT_TYPES.CUSTOM.value || checkIndividualSettings(customSettings, fieldId)
};

export const isSectionVisibleByReportTypes = (chartName, reportType, customTypeSettings, availableReportTypes) => {
  return availableReportTypes.includes(reportType) ||
    (reportType === REPORT_TYPES.CUSTOM.value && checkIndividualSettings(customTypeSettings, chartName, reportType))
};

export const isExpertSectionVisible = (chartName, reportType, customTypeSettings) => {
  return isSectionVisibleByReportTypes(chartName, reportType, customTypeSettings, [REPORT_TYPES.EXPERT.value, REPORT_TYPES.PRO.value])
};

export const isProSectionVisible = (chartName, reportType, customTypeSettings) => {
  return isSectionVisibleByReportTypes(chartName, reportType, customTypeSettings, [REPORT_TYPES.PRO.value])
};

export const getRegExSafeString = (stringToMakeSafe) => {
 return stringToMakeSafe.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // escapes all 'dangerous' characters in searchWord
}

const getSharedSettings = () => getFromStorage(SHARED_SETTINGS_KEY) || {};

export const getSubSystem = (subsystemName) => {
  // Function to get sub system or empty dict
  return _.get(getSharedSettings(), `sub_systems.${subsystemName}`, {});
};

export const getTranslates = (field) => {
  return _.get(getSharedSettings(), ['translates', field], {});
};

export const isSubSystemEnabled = (subsystemName) => {
  // Function to check if subsystem is enabled

  return !!(_.get(getSubSystem(subsystemName), 'enabled'));
}

export const getSubSystemConfigItem = (subsystemName, pathInConfig) => {
  // Function to get value from config of subSystem

  return _.get(getSubSystem(subsystemName), `config.${pathInConfig}`)
}

export const isInstrumentsDataExist = (instrumentList) => {
  return instrumentList.data && instrumentList.data.some(portfolio => {
    return (_.isArray(portfolio.components) && portfolio.components.length > 0)
      || (_.isArray(portfolio.clearing_account) && portfolio.clearing_account.length > 0);
  });
};

export const setDefaultValue = (target, fieldName, value) => {
  if (!target.hasOwnProperty(fieldName)) {
    target[fieldName] = value;
  }
};

export const isWeekendOrHoliday = (date) => !momentBusinessDays(date).isBusinessDay() || isGermanHoliday(date);

export const isGermanHoliday = (date) => {
  const year = date.year();
  // calculate Easter
  const C = Math.floor(year / 100);
  const N = year - 19 * Math.floor(year / 19);
  const K = Math.floor((C - 17) / 25);
  let I = C - Math.floor(C / 4) - Math.floor((C - K) / 3) + 19 * N + 15;
  I = I - 30 * Math.floor(I / 30);
  // prettier-ignore
  I = I - Math.floor(I / 28) * (1 - Math.floor(I / 28) * Math.floor(29 / (I + 1)) * Math.floor((21 - N) / 11));
  let J = year + Math.floor(year / 4) + I + 2 - C + Math.floor(C / 4);
  J = J - 7 * Math.floor(J / 7);
  const L = I - J;
  const Month = 3 + Math.floor((L + 40) / 44);
  const Day = L + 28 - 31 * Math.floor(Month / 4);

  const padout = (num) => {
    return num < 10 ? `0${num}` : `${num}`;
  };

  const easter = `${year}-${padout(Month)}-${padout(Day)}`;

  const holidays = [
    moment(`${year}-01-01`).format(DATE_FORMAT), // Neujahr
    moment(`${easter}`).subtract(2, 'days').format(DATE_FORMAT), // Karfreitag
    moment(`${easter}`).add(1, 'days').format(DATE_FORMAT), // Ostermontag
    moment(`${year}-05-01`).format(DATE_FORMAT), // TagDerArbeit
    moment(`${year}-12-24`).format(DATE_FORMAT), // Weihnachten
    moment(`${year}-12-25`).format(DATE_FORMAT), // Weihnachtsfeiertag1
    moment(`${year}-12-26`).format(DATE_FORMAT), // Weihnachtsfeiertag2:
    moment(`${year}-12-31`).format(DATE_FORMAT) // Silvester
  ];

  return holidays.includes(date.format(DATE_FORMAT));
};

export const getNextBusinessDate = (date) => {

  if (!isWeekendOrHoliday(date)) {
    return date;
  }

  return getNextBusinessDate(momentBusinessDays(date).nextBusinessDay());
};

/**
 * Update current location state values without replacing pathname and search.
 */
export const updateHistoryStateValue = (history, key, value) => {
  history.replace({
    // Use current pathname and search,
    // so only state could be updated without changing current location
    pathname: window.location.pathname,
    search: window.location.search,
    state: {
    ...(history.state || {}), [key]: value
  }})
}

export const isExPostCostEnabled = (() => {
  return !!(_.get(getSharedSettings(), 'sub_systems.reporting.config.is_ex_post_cost_enabled'));
})();

export const formatDateShouldBeGraterThenErrorMessage = (date) => `Das Datum darf nicht vor dem ${date.format('DD.MM.YYYY')} liegen.`;

/*
* Converts server (UTC) date into local moment
* */
export const toLocalDateTime = (serverDate) => {
  return moment.utc(serverDate).local();
};

export const buildBenchmarkName = (benchmarks) => {
  const names = benchmarks.map((benchmark) => {
    return `${parseInt(benchmark.percentage || 0)}% ${benchmark.name}`;
  });

  return `Benchmark: ${names.join(', ')}`
};

export function openInNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href: href,
  }).click();
};
import React from "react";
import _ from "lodash";

import {PortfolioHandlerResource} from "../../../utils/api";
import { getHashValue, getPortfolioChartName } from '../utils';
import { useTabsSharedDataContext } from '../components_v2/TabsSharedData/TabsSharedData';
import { AGGREGATED_PORTFOLIO_ID } from '../../../utils/utils';
import { CHART_COLORS } from '../../../utils/constants';

const generateQueryParamsHash = (customerId, portfolios) => {
  const portfoliosDepotNumbers = JSON.stringify(_.sortBy(
    portfolios.map((portfolio) => portfolio.depotNumber)));
  return getHashValue(customerId, portfoliosDepotNumbers)
};

const DATA_KEY = 'sustainability-metrics-data';

export function useCustomerESGProfileData(customerId, portfolios, aggregatedPortfolioName, dataProvider=PortfolioHandlerResource) {

  const [data, setData] = React.useState({
    data: null,
    loading: true,
    error: null,
    updatedAt: +new Date(),
  });

  const sharedData = useTabsSharedDataContext();

  const getDepotNumber = (ptf) => ptf.depotNumberForAssets || ptf.depotNumber;

  const fetch = async () => {
    const hashKey = generateQueryParamsHash(customerId, portfolios);
    const state = {
      data: null,
      loading: true,
      error: null,
      updatedAt: +new Date()
    };
    setData(state);
    sharedData.setDataValue(DATA_KEY, hashKey, state);

    try {
      const depots = portfolios.reduce((acc, cur) => {
        acc[getDepotNumber(cur)] = cur;
        return acc;
      }, {});

      if (!depots.hasOwnProperty(AGGREGATED_PORTFOLIO_ID)) {
        depots[AGGREGATED_PORTFOLIO_ID] = {
          id: AGGREGATED_PORTFOLIO_ID,
          name: aggregatedPortfolioName,
          color: CHART_COLORS[0],
          number: AGGREGATED_PORTFOLIO_ID
        }
      }

      const depotNumbers = portfolios.map(getDepotNumber).filter((value) => !!value);
      const response = await dataProvider.getCustomerESGProfileData(customerId, depotNumbers);
      const esgData = []; // ordered list
      const depotNumbersAsStr = depotNumbers.map(_.toString);
      _.orderBy(Object.keys(response.data), (depotId) => _.indexOf(depotNumbersAsStr, depotId)).forEach((depotId) => {
        esgData.push({
          ...response.data[depotId],
          name: getPortfolioChartName(depots[depotId], aggregatedPortfolioName),
          color: [depots[depotId].color]
        });
      });

      const state = {
        data: {...response, data: esgData},
        loading: false,
        error: null,
        updatedAt: +new Date()
      };
      setData(state);
      sharedData.setDataValue(DATA_KEY, hashKey, state);
    } catch (error) {
      const state = {
        data: null,
        loading: false,
        error: error,
        updatedAt: +new Date()
      };
      setData(state);
      sharedData.setDataValue(DATA_KEY, hashKey, state);
    }
  };

  React.useEffect(() => {
    if (!!customerId && !_.isEmpty(portfolios)) {

      const sharedPortfolioData = !!sharedData
        ? sharedData.getDataValue(DATA_KEY, generateQueryParamsHash(customerId, portfolios))
        : undefined;

      if (!!sharedPortfolioData && !sharedPortfolioData.errors) {
        // sync internal hook data with shared data.
        setData(sharedPortfolioData);
        return;
      }

      // fetch data if there is no shared data or shared data was loaded with error
      // check if data fetched for the first time (updatedAt is undefined) to prevent infinite data reloading
      // in case of data loading error, so data will be requested again only if tab changed
      if (!data.updatedAt || !sharedPortfolioData) {
        fetch();
      }
    }
  }, [customerId, JSON.stringify(portfolios), _.get(sharedData, 'updatedAt')]);

  return data

}
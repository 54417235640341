import React from 'react';
import connect from "react-redux/lib/connect/connect";
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import useStyles from './styles';
import DeliveryOptions from './components/Tabs/DeliveryOptions';
import ReportSettings from './components/Tabs/ReportSettings';
import ReportSettingsV2 from './components/Tabs/ReportSettingsV2';
import CoverLetter from './components/Tabs/CoverLetter';
import PreviewScreen from './components/Tabs/PreviewScreen';
import PeriodSelection from "./components/Tabs/PeriodSelection";
import ScrollMenu from "react-horizontal-scrolling-menu";
import clsx from "clsx";
import Resource, {CustomerReportSettingResource, GroupResource} from '../../utils/api';
import { SENDING_DATE } from './components/ReportSendingDate/constants';
import { REPORTS_OPTIONS } from './components/AdditionalReportOptions/constants';
import {withRouter} from "react-router-dom";
import moment from "moment";
import {executeIfPathExist, getInvestmentDynamicPath} from "../InvestmentPlatform/utils";
import NavigationButtons from './components/Tabs/NavigationButtons';
import _ from 'lodash';
import {SETTINGS_TYPE_BENCHMARK, REPORT_SETTINGS_TYPE} from './constants';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  setReportingCustomers,
  setReportingGroups
} from "../SingleReporting/actions";
import withNotification from "../../components/NotificationProvider";
import {AUTO_REPORT_PERIODICITY} from './constants';
import {getErrorMessage} from "../../utils/utils";
import {setHeaderInfo} from "../InvestmentPlatform/actions";
import CustomerInfo
  from "../Reporting/components/PreviewStepContent/components/CustomerConfiguration/components/CustomerInfo";
import {
  REPORTS_AREAS
} from '../DashboardSettings/components/CustomersList/components/ReportsAreasVisibility/constants';



const Reporting = (props) => {
  const {
    history,
    dispatch,
    groups,
    customers,
    investmentPlatform,
    isAutoReport,
    isGroupReporting,
    customersLoading,
    groupsLoading,
    newDesign,
  } = props;

  const isDataLoading = isGroupReporting ? groupsLoading : customersLoading;
  const [isMounted, setMountingStatus] = React.useState(false);

  // get all settings for groups or customers
  const groupedReportsSettings = React.useMemo(() => {
    if(isGroupReporting){
      return groups.reduce((a, v) => ({ ...a, [v.id]: v}), {})
    }
    else {
      return _.flatten(customers).reduce((a, v) => ({...a, [v.customer_id]: {
        ...v,
        name: [v.customer_full_name, v.customer_id].join(' - '),
      }}), {});
    }
  }, [groups, customers]);
  let allReportsSettings = Object.values(groupedReportsSettings);

  let _menuItems = [
    {uid: 1, isLastTab: false, name: 'Versandeinstellungen', component: DeliveryOptions, additionalButton: false},
    {uid: 2, isLastTab: false, name: 'Reporteinstellungen', component: newDesign ? ReportSettingsV2 : ReportSettings, additionalButton: false},
    {uid: 3, isLastTab: false, name: 'Anschreiben', component: CoverLetter, additionalButton: false},
    {uid: 4, isLastTab: true, name: 'Versandvorschau', component: PreviewScreen, additionalButton: false},
  ];

  // append periodicity selection tab for auto reports
  if (isAutoReport){
    _menuItems.unshift(
      {uid: 0, isLastTab: false, name: 'Zeitraumwahl', component: PeriodSelection, additionalButton: false}
    )
  }
  const [selectedPeriodicity, setSelectedPeriodicity] = React.useState({}); // moved to have possibility to move back with keine

  const isReturnToList = !!(selectedPeriodicity && selectedPeriodicity.id === AUTO_REPORT_PERIODICITY.NONE.id);

  // dropdown with other groups'/customers' settings should be visible on the first tab
  _menuItems[0].additionalButton = true;

  const classes = useStyles();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [nextBtnDisabled, setNextBtnDisabled] = React.useState(false);
  const [menuItems, setMenuItems] = React.useState(_menuItems);
  const [benchmarks, setBenchmarks] = React.useState([]);

  // reportSettings is currently selected report setting that we are working with
  const [reportSettings, setReportSettings] = React.useState({});

  const [updatingReportSettings, setUpdatingReportSettings] = React.useState({
    loading: false,
    updateSuccess: undefined // todo it seems that this key is not used, check if it can be removed, object changed to bool variable
  });


  React.useEffect(() => {
    if(_.isEmpty(groupedReportsSettings)){
      return;
    }
    setMountingStatus(true);
    let settingsToLoad;
    if (isGroupReporting){
      settingsToLoad = groupedReportsSettings[+props.match.params.group_id] || {};
    } else {
      let settings = groupedReportsSettings[props.match.params.customer_id] || {};
      // after page reload customer_type filter is set to default, so settings might be empty -> make request to get data
      if(_.isEmpty(settings)){
        fetchSingleCustomerReportSettings();
        return;
      }
      settingsToLoad = {...settings['report_settings'], ..._.pick(settings, ['customer_id', 'name', 'auto_settings'])};
    }

    if(isAutoReport){
      fetchAutoReportData(settingsToLoad)
    } else {
     setReportSettings({...settingsToLoad, 'is_auto_report': false});
    }

    loadBenchmarks(isAutoReport && !isGroupReporting ? settingsToLoad.customer_id : settingsToLoad.id);
  }, [groupedReportsSettings]);

  React.useEffect(() => {
    if(isMounted) {
      let data, component;
      if (isGroupReporting){
        data = groupedReportsSettings[+props.match.params.group_id] || {};
        component = <div className={clsx(classes.customerInfo, classes.groupHeader)}>{data.name}</div>
      } else {
        data = groupedReportsSettings[props.match.params.customer_id] || {};
        component = <CustomerInfo customer={data} withSRRI={false} />
      }

      dispatch(setHeaderInfo(component));
    }
  }, [isMounted]);

  const fetchAutoReportData = async(settingsToLoad) => {
    let response = await new Resource('reports/').at('broker/auto-report-settings/').get({group_id: settingsToLoad.id, customer_id: settingsToLoad.customer_id});
    setReportSettings({...response, 'is_auto_report': true})
  };

  const fetchSingleCustomerReportSettings = async() => {
    try {
      let params = {
        settings_type: getSettingsTypeBenchmark(),
        is_auto_report: isAutoReport
      };
      let response = await CustomerReportSettingResource.at(`customer/single/settings/${props.match.params.customer_id}/`).get(params);
      setReportSettings(response.settings_data);

      let benchmarks_data = response.benchmarks_data;
      benchmarks_data = benchmarks_data.map(item => ({
        ...item,
        weight: +item.percentage
      }));
      setBenchmarks(benchmarks_data);
    } catch (error) {
      goToMainReportingPage();
      props.displayNotification('error', getErrorMessage(error));
    }
  };

  const updateSettingWithActual = (settings) => {
    setReportSettings(settings);

     // function to update settings to show list with Actual settings
    if(!isGroupReporting) {
      if(groupedReportsSettings[props.match.params.customer_id]){
        let settingsToUpdate = isAutoReport ? 'auto_report_settings' : 'report_settings';
        groupedReportsSettings[props.match.params.customer_id][settingsToUpdate] = settings;
        dispatch(setReportingCustomers(Object.values(groupedReportsSettings)));
      }
    } else {
      if(isAutoReport){
        groupedReportsSettings[props.match.params.group_id]['auto_settings'] = settings;
      } else {
        groupedReportsSettings[props.match.params.group_id] = {...groupedReportsSettings[props.match.params.group_id], ...settings};
      }

      dispatch(setReportingGroups(Object.values(groupedReportsSettings)));
    }
  };

    const onNextClick = () => {

      setUpdatingReportSettings({
        loading: true,
        updateSuccess: undefined
      });

      let body = [{
        id: reportSettings.id,
        report_type: reportSettings.report_type,
        distribution_type: reportSettings.distribution_type,
        date_range: reportSettings.date_range,
        date_range_start_date: reportSettings.date_range == SENDING_DATE.CUSTOM.value ?
          reportSettings.date_range_start_date || null :
          reportSettings.date_range_start_date,
        date_range_end_date: reportSettings.date_range == SENDING_DATE.CUSTOM.value ?
          reportSettings.date_range_end_date || moment().format('YYYY-MM-DD') :
          reportSettings.date_range_end_date,
        with_cost: reportSettings.with_cost,
        with_transactions: reportSettings.with_transactions,
        with_payment_plans: reportSettings.with_payment_plans,
        with_profit_loss: reportSettings.with_profit_loss,
        with_other_assets: reportSettings.with_other_assets,
        include_historical_portfolios: reportSettings.include_historical_portfolios,
        with_transaction_saldo: reportSettings.with_transaction_saldo,
        cover_message_content: reportSettings.cover_message_content,
        cover_message_enabled: reportSettings.cover_message_enabled,
        is_auto_report: isAutoReport, // key that indicates back-end which model to use
        periodicity: reportSettings.periodicity,
        delay: reportSettings.delay,
        benchmark_enabled: reportSettings.benchmark_enabled,
        with_invested_capital: reportSettings.with_invested_capital,
        aggregate_historical_chart_portfolios: reportSettings.aggregate_historical_chart_portfolios,
        aggregate_performance_chart_portfolios: reportSettings.aggregate_performance_chart_portfolios,
        custom_report_type_settings: reportSettings.custom_report_type_settings,
        individual_settings: reportSettings.individual_settings,
      }];

      isGroupReporting ? handleSaveGroupReportSettingsUpdates(body) : handleSaveCustomerReportSettingsUpdates(body);
    };

    const goToNextTab = () => {
    /** Redirects to next tab */
    if(selectedTab + 1 === menuItems.length || isReturnToList){ goToMainReportingPage() } //check if last tab
    setSelectedTab(selectedTab+1)
  };

    const goToMainReportingPage = () => {
      executeIfPathExist(investmentPlatform.routes, 'SERIES_REPORTING', (path) => (
        history.push(`/${getInvestmentDynamicPath()}${path}/?is_from_group=${!!isGroupReporting}`)
      ));
    };
    const onBackClick = () => {
      if(selectedTab === 0){
        goToMainReportingPage();
      }
      else {setSelectedTab(selectedTab-1)}

      setNextBtnDisabled(false)
    };

    const handleSettingUpdate = (fieldname) => (value) => {
      if (fieldname === 'additional_reports') {
        setReportSettings((prevState) => ({
          ...prevState,
          with_cost: value.includes(REPORTS_OPTIONS.COST.value),
          with_transactions: value.includes(REPORTS_OPTIONS.TRANSACTIONS.value)
        }))
      } else if (fieldname === 'areas_visibility') {
        setReportSettings((prevState) => ({
          ...prevState,
          with_payment_plans: value.includes(REPORTS_AREAS.PAYMENT_PLANS.value),
          with_profit_loss: value.includes(REPORTS_AREAS.PROFIT_LOSS.value),
          with_other_assets: value.includes(REPORTS_AREAS.OTHER_ASSETS.value),
          include_historical_portfolios: value.includes(REPORTS_AREAS.INCLUDE_HISTORICAL_PORTFOLIOS.value),
          with_transaction_saldo: value.includes(REPORTS_AREAS.WITH_TRANSACTION_SALDO.value)
        }))
      } else if (fieldname === 'benchmark_updates') {
        setReportSettings((prevState) => ({
          ...prevState,
          benchmark_enabled: value.benchmark_enabled,
          benchmarks: value.benchmarks,
        }))
      } else {
        setReportSettings((prevState) => ({
          ...prevState,
          [fieldname]: value
        }))
      }
    };
    const handleSaveGroupReportSettingsUpdates = async(body) => {
      /* Saves auto and ordinary group report settings */
      body[0].report_generation_type = reportSettings.report_generation_type
      body[0].group = props.match.params.group_id;

      try {
        let response = await GroupResource.at('list/').patch(body);
        setUpdatingReportSettings({
          loading: false,
          updateSuccess: true
        });

        let updatedSettings = {
          ...reportSettings,
          next_generation_date: _.get(response, '0.next_generation_date'),
          last_report: _.get(response, '0.last_report'),
        };

        // if group had no auto settings, after patch auto setting is created and id needs to be set
        if (_.isNil(reportSettings.id)) {
          updatedSettings['id'] = response[0].id;
        }

        updateSettingWithActual(updatedSettings);
        goToNextTab(); // go to next tab should happen hear to prevent overriding settings if the request ends to late

        setTimeout(() => {
          setUpdatingReportSettings({
            loading: false,
            updateSuccess: undefined
          });
        }, 2000);
      } catch (error) {
        props.displayNotification('error', getErrorMessage(error));
        setUpdatingReportSettings({
          loading: false,
          updateSuccess: false
        })
      }
    };

    const handleSaveCustomerReportSettingsUpdates = async(body) => {
      /* Saves auto and ordinary customer report settings */
      body[0].customer_id = reportSettings.customer_id;
      body[0].portfolio_ids = reportSettings.portfolio_ids;
      try {
        let response = await CustomerReportSettingResource.at('customer/settings/create/').post({data: body});

        let updatedSettings = {
          ...reportSettings,
          next_generation_date: _.get(response, '0.auto_report_settings.next_generation_date'),
          last_report: _.get(response, '0.report_settings.last_report'),
        };
        // if customer had no settings, after patch setting is created and id needs to be set
        if (_.isNil(reportSettings.id)) {
          updatedSettings['id'] = response[0].report_settings.id;
        }

        updateSettingWithActual(updatedSettings);
        goToNextTab(); // go to next tab should happen hear to prevent overriding settings if the request ends to late

        setUpdatingReportSettings({
          loading: false,
          updateSuccess: true
        });

        setTimeout(() => {
          setUpdatingReportSettings({
            loading: false,
            updateSuccess: undefined
          });
        }, 2000);
      } catch (e) {
        props.displayNotification('error', e);
        setUpdatingReportSettings({
          loading: false,
          updateSuccess: false
        })
      }
    };

    const copeOtherReportSettings = async(settings) => {
      if(settings){
        if(isAutoReport){
          settings = await new Resource('reports/').at('broker/auto-report-settings/').get({group_id: settings.id, customer_id: settings.customer_id})
        } else if(!isGroupReporting) {
          settings = settings.report_settings // for single customer reporting we need to get report_settings from all settings
        }

        setReportSettings({
          ...reportSettings,
          report_type: settings.report_type,
          report_generation_type: settings.report_generation_type,
          distribution_type: settings.distribution_type,
          date_range: settings.date_range,
          date_range_start_date: settings.date_range_start_date || null,
          date_range_end_date: settings.date_range_end_date || moment().format('YYYY-MM-DD'),
          with_cost: settings.with_cost,
          with_transactions: settings.with_transactions,
          with_payment_plans: settings.with_payment_plans,
          with_profit_loss: settings.with_profit_loss,
          with_other_assets: settings.with_other_assets,
          include_historical_portfolios: settings.include_historical_portfolios,
          with_transaction_saldo: settings.with_transaction_saldo,
          cover_message_content: settings.cover_message_content,
          cover_message_enabled: settings.cover_message_enabled,
          cover_message_updated: moment(),
          is_auto_report: isAutoReport,
          delay: settings.delay,
          periodicity: settings.periodicity,
          benchmark_enabled: settings.benchmark_enabled,
        });
      }
    };

    const getSettingsTypeBenchmark = () => {
      if(isGroupReporting){
        return isAutoReport ? SETTINGS_TYPE_BENCHMARK.GROUP_AUTO_REPORT_BENCHMARK : SETTINGS_TYPE_BENCHMARK.GROUP_BENCHMARK
      }
      else {
        return isAutoReport ? SETTINGS_TYPE_BENCHMARK.CUSTOMER_AUTO_REPORT_BENCHMARK : SETTINGS_TYPE_BENCHMARK.CUSTOMER_BENCHMARK
      }
    };

    const handleBenchmarkUpdate = async(benchmarks) => {
      try {
        const body = benchmarks.map(benchmark => (
          {settings: reportSettings.id, benchmark: benchmark.id, percentage: benchmark.weight}
        ));

        await CustomerReportSettingResource.at(`report-benchmarks/benchmarks/${reportSettings.id}/type/${getSettingsTypeBenchmark()}/`).post(body);
        setBenchmarks(benchmarks)

      } catch (error) {
        props.displayNotification('error', getErrorMessage(error));
      }
    };

    const loadBenchmarks = async(setting_id) => {
      if(_.isNil(setting_id)) return;

      try {
        let params = {
          settings_type: getSettingsTypeBenchmark(),
          is_auto_report: +isAutoReport
        };
        let response = await CustomerReportSettingResource.at(`report-benchmarks/benchmarks/${setting_id}/`).get(params);
        let data = response.map(item => ({
          ...item,
          weight: +item.percentage
        }));
        setBenchmarks(data);
      } catch (error) {
        props.displayNotification('error', getErrorMessage(error));
      }
    };

    const renderTabs = () =>  {
        return menuItems.map((tab, index) => (
          <div
            className={classes.tabContent}
            key={'tab' + index}
            role="tabpanel"
            hidden={selectedTab !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
          >
            {selectedTab === index && (
              <>
              <tab.component
                loading={updatingReportSettings.loading}
                reportSettings={reportSettings}
                handleSettingUpdate={handleSettingUpdate}
                dispatch={dispatch}
                isGroupReporting={isGroupReporting}
                withSettingsButton={menuItems[selectedTab].additionalButton}
                allSettings={isAutoReport ? allReportsSettings.filter(item => item.auto_settings != null) : allReportsSettings}
                handleSelectOtherReportSettings={copeOtherReportSettings}
                benchmarks={benchmarks}
                handleBenchmarkUpdate={handleBenchmarkUpdate}
                setNextBtnDisabled={setNextBtnDisabled}
                selectedPeriodicity={selectedPeriodicity}
                setSelectedPeriodicity={setSelectedPeriodicity}
                isAutoReport={isAutoReport}
              />
              </>
            )}
          </div>
        ));
      };

  if (isDataLoading) {
    return <div className={classes.pageLoader}><CircularProgress /></div>
  }

  return (
    <React.Fragment>
      <Container className={classes.reportingPageContainer} >
        {/* tabs in header */}
        <Grid container className={classes.topContainer} alignItems={'center'}>
          <Grid item xs={12}>
            <ScrollMenu
              alignCenter={false}
              wheel={false}
              scrollToSelected={true}
              hideSingleArrow={true}
              arrowLeft={<div className={classes.menuArrows}>{"<"}</div>}
              arrowRight={<div className={classes.menuArrows}>{">"}</div>}
              dragging={false}
              selected={menuItems && menuItems[selectedTab] && menuItems[selectedTab].uid}
              data={menuItems && menuItems.map((item, idx) => (
                <div key={item.uid} className={clsx(classes.menuItem, selectedTab === idx ? 'active' : '', item.isDone ? 'done': '')}>
                  {item && item.isDone && <i className={'fa fa-check-circle'}></i>}
                  {item.name}
                  {selectedTab === idx && <i className={'fa fa-lg fa-angle-up'}></i>}
                </div>
              ))}
            />
          </Grid>
        </Grid>

        {/* tabs content */}
        <Grid container className={classes.tabsContainerWrapper}>
          {/* last tab is preview, to make preview page width responsive - overflow: 'hidden' is used */}
          <Grid item xs={12} className={classes.tabsContainer} style={selectedTab === menuItems.length - 1 ? {overflow: 'hidden'} : {}}>
            {renderTabs()}
          </Grid>
        </Grid>
      </Container>

      <div className={classes.footerContainer}>
        <Grid container className={classes.footerContent}>
          <NavigationButtons
            onNextClick={onNextClick}
            onBackClick={onBackClick}
            isLastTab={menuItems[selectedTab].isLastTab || isReturnToList}
            nextBtnDisabled={nextBtnDisabled}
            loading={updatingReportSettings.loading}
            reportSettings={reportSettings}
            newDesign={newDesign}
          />
        </Grid>
      </div>
    </React.Fragment>
  )
};

const mapStateToProps = (state) => ({
  customers: state.get('reporting').toJS().customers,
  customersLoading: state.get('reporting').toJS().customersLoading,
  investmentPlatform: state.get('investmentPlatform').toJS(),
  groups: state.get('reporting').toJS().groups,
  groupsLoading: state.get('reporting').toJS().groupsLoading,
});
Reporting.defaultProps = {
  isAutoReport: REPORT_SETTINGS_TYPE.DEFAULT_SETTINGS,
};

export default connect(mapStateToProps)(withNotification(withRouter(Reporting)));
import moment from 'moment';

import {CUSTOMER_ID_KEY, getFromStorage, NEW_ORIGIN_KEY} from "./storage";

export const noBenchmarkValue = 'Keine Benchmark gesetzt';
export const fontSizeTable = 14;
export const smFontSizeTable = 12;

export const chartLegendSize = 14;
export const smChartLegendSize = 12;

export const fontSize = '16px';
export const smFontSize = '14px';
export const xsFontSize = '13px';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const SERVER_ERROR = 'Interner Serverfehler. Bitte laden Sie die Seite später erneut.';
export const NOT_FULL_INSTR_DATA_MSG = 'Für dieses Instrument stehen für den gewählten Zeitraum nicht ausreichend Daten zur Verfügung.'

export const FODB_CUSTODIAN_ID = '10968';

export const REQUEST_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

export const BCA_PROD_LOGO_DOMAINS = [
  "https://dev-bca.az.fincite.net",
  "https://bca.prod.fincite.net",
  "https://vermoegen.diva.bca.de",
  "https://vermoegen.diva.carat-ag.de",
  "https://vermoegen.diva.bfv-ag.de",
  "https://vermoegen.diva.bca-vvs.de"
];

export const BEGINNING = 'BEGINNING';
export const CUSTOM = 'CUSTOM';

// in the dashboard settings model and on front keys differ, so we use this mapping
export const REPORT_DATE_PERFORMANCE_TIME_TYPE_MAPPING = {
  '4': 'BEGINNING', '1': 'LAST_1_YEARS', '2': 'LAST_3_YEARS',
  '3': 'LAST_5_YEARS', '0': 'CUSTOM', 'YTD': 'YTD'
};


export const CUSTOM_PERFORMANCE_TIME_TYPE_VALUE = 0;
export const BEGINNING_PERFORMANCE_TIME_TYPE_VALUE = 4;

export const PERFORMANCE_TIME_TYPE = {
  CUSTOM: {
    value: CUSTOM_PERFORMANCE_TIME_TYPE_VALUE,
    description: 'Eigene Auswahl',
    getDateRange: () => {

      let sessionCustomerId = getFromStorage(CUSTOMER_ID_KEY);
      let sessionSelectedStartDateKey = 'selected_start_date_'.concat(sessionCustomerId);
      let sessionSelectedEndDateKey = 'selected_end_date_'.concat(sessionCustomerId);

      return {
        start: moment(getFromStorage(sessionSelectedStartDateKey)),
        end: moment(getFromStorage(sessionSelectedEndDateKey))
      }
    }
  },
  YTD: {
    value: 'YTD',
    description: 'YTD',
    getDateRange: () => ({
      start: moment().startOf('year'),
      end: moment()
    })
  },
  LAST_1_YEARS: {
    value: 1,
    description: '1J',
    getDateRange: () => ({
      start: moment().subtract(1, 'years'),
      end: moment()
    })
  },
  LAST_3_YEARS: {
    value: 2,
    description: '3J',
    getDateRange: () => ({
      start: moment().subtract(3, 'years'),
      end: moment()
    })
  },
  LAST_5_YEARS: {
    value: 3,
    description: '5J',
    getDateRange: () => ({
      start: moment().subtract(5, 'years'),
      end: moment()
    })
  },
  [BEGINNING]: {
    value: BEGINNING_PERFORMANCE_TIME_TYPE_VALUE,
    description: 'Max',
    getDateRange: () => ({
      start: undefined,
      end: undefined
    })
  },
};


export const DEFAULT_PERFORMANCE_TIME_TYPE = PERFORMANCE_TIME_TYPE.BEGINNING;
export const LONGER_TIME_FRAME_YEARS = 5;
export const LONGER_PORTFOLIO_COUNTS = 4;


export const PERMISSION_TYPE = {
  ALLOW_ANY: 'ALLOW_ANY',
  AUTHORIZED_ONLY: 'AUTHORIZED_ONLY',
  ANONYMOUS_ONLY: 'ANONYMOUS_ONLY',
  BROKER_ONLY: 'BROKER_ONLY',
  CUSTOMER_ONLY: 'CUSTOMER_ONLY'
};

export const DATE_FORMAT = 'DD.MM.YYYY';

export const BENCHMARK_CHART_COLOR = '#5ec14e';
export const INVESTMENT_CHART_COLOR = '#E3A600';

export const LIGHTENING_INDEX_PERCENT = 15;
export const MAX_LIGHTENING_INDEX = 6;

/** Text of the message, that should be displayed, after error will be occured */
export const ERROR_MODAL_MESSAGE = 'Verbindung zur Datenbank momentan nicht möglich. Bitte versuchen Sie es später erneut.';
export const PERMISSION_ERROR_MODAL_MESSAGE = 'Sie besitzen nicht ausreichend Berechtigungen, um diese Aktion auszuführen.';
export const CUSTOMER_DOES_NOT_EXIST_ERROR_MODAL_MESSAGE = 'Der von ihnen gewählte Kunde existiert in unserer Datenbank nicht.';
export const SELECTED_LONGER_TIMEFRAME = 'Die Kalkulation kann unter Umständen etwas länger dauern'
export const NEED_TO_REFRESH_PAGE = 'Die Berechnung dauert etwas länger. Bitte laden Sie die Seite neu.';

export const NO_DATA = 'No data';

export const OTHER_TYPE_TRANSLATION = {
  'Other': 'Sonstige',
  [NO_DATA]: 'Keine Daten verfügbar'
};
// TODO: use from backend (move codes to backend)
export const ALLOCATION_REGIONS = {
  "Africa and Near East": {
    name: 'Afrika und naher Osten',
    code: 'AF'
  },
  "North America": {
    name: 'Nordamerika',
    code: 'AM'
  },
  "Asia - Pacific": {
    name: 'Asien',
    code: 'AS'
  },
  "Europe": {
    name: 'Europa',
    code: 'EU'
  },
  "Central and South America": {
    name: 'Zentral- und Südamerika',
    code: 'LA'
  },
  "Other": {
    name: OTHER_TYPE_TRANSLATION.Other,
    code: ''
  },
  [NO_DATA]: {
    name: OTHER_TYPE_TRANSLATION[NO_DATA],
    code: NO_DATA,
  }
};

export const MATURITY_MAP = {
  '0-1': 'Bis 1 Jahr',
  '1-3': '1-3 Jahren',
  '3-5': '3-5 Jahren',
  '5-7': '5-7 Jahren',
  '7-10': '7-10 Jahren',
  '10-15': '10-15 Jahren',
  '15-20': '15-20 Jahren',
  '20-30': '20-30 Jahren',
  'Over 30': '> 30 Jahren',
  ...OTHER_TYPE_TRANSLATION
};

export const CREDIT_QUALITY_MAP = {
  'Below B': 	'Unter B',
  'Not Rated': 'Nicht gerated',
  ...OTHER_TYPE_TRANSLATION
};

export const FIXED_INCOME_SUB_TYPE_MAP = {
  'IG/Corporates': 'Unternehmensanleihen',
  'IG/Govt & Supra': 'Staatsanleihen',
  ...OTHER_TYPE_TRANSLATION
};

export const VALUES_TOOLTIPS = {
  'performance_table': 'Berechnet nach zeitgewichteter Methode.',
  'yearly_performance_bar_chart': 'Berechnet nach geldgewichteter Methode bzw. nach Modified Dietz.',
  'return': 'Gesamtrendite innerhalb des betrachteten Zeitraums, berechnet nach geldgewichteter Methode bzw. nach Modified Dietz.',
  'return_pa': 'Durchschnittliche Jahresrendite im betrachteten Zeitraum, berechnet nach geldgewichteter Methode bzw. nach Modified Dietz.',
  'time_weighted_return': 'Gesamtrendite innerhalb des betrachteten Zeitraums, berechnet nach zeitgewichteter Methode.',
  'time_weighted_return_pa': 'Durchschnittliche Jahresrendite im betrachteten Zeitraum, berechnet nach zeitgewichteter Methode.',
  'expected_shortfall': 'Der Expected Shortfall ist eine Kennzahl zur Quantifizierung von finanzwirtschaftlichen ' +
      'Risiken. Der Expected Shortfall (ES) zählt wie der Value-at-Risk zu den Risikomaßen, die das Risiko als Wahrscheinlichkeit ' +
      'einer negativen Abweichung von einem Erwartungswert (down side risk) beziffern.',

  'sharpe_ratio': 'Die Sharpe Ratio ist eine Kennzahl, die die erwartete Überschussrendite eines Finanzinstruments mit dessen Volatilität ' +
      'vergleicht. Unter der Überschussrendite versteht man die über die sichere Geldanlage (hier: Risikoloser Zinssatz = 0%) ' +
      'hinausgehende jährliche Verzinsung eines Investments. Anhand der Sharpe Ratio können vergangene Wertentwicklungen von ' +
      'Wertpapieren oder ganzen Portfolios verglichen werden.',

  'tracking_error': 'Der Tracking Error ist eine Kennzahl, die angibt, wie stark die Wertentwicklung des Fonds von der entsprechenden ' +
      'Benchmark abweicht. Fällt der Error gering aus, bedeutet dies eine sehr ähnliche Entwicklung zur Benchmark. Im Extremfall verlaufen ' +
      'beide Entwicklungen synchron, dann beträgt der Fehler 0%. Aktive Fonds, die eine Outperformance zum Index erzielen möchten, sollten ' +
      'deshalb einen entsprechend hohen Tracking Error haben.',

  'information_ratio': 'Die Information Ratio ist eine Kennzahl für die Bewertung eines Fonds. Sie gibt an, ob ein Fonds durch die Abweichung ' +
      'der Portfoliozusammensetzung vom Index eine höhere oder eine niedrigere Rendite als dieser erzielen konnte. Die Zahl ergibt sich aus ' +
      'dem Verhältnis zwischen der Differenz der Fonds- und Indexrendite und dem Tracking Error. Je größer diese Zahl ist, desto besser.',

  'alpha': 'Das Alpha ist eine Kennzahl zur Veranschaulichung der abweichenden Wertentwicklung eines Fonds gegenüber der Entwicklung der ' +
      'verwendeten Benchmark. Es misst jenen Teil der Rendite, der nicht mit der allgemeinen Marktentwicklung zu erklären ist, sondern ' +
      'mit den Entscheidungen des Fondsmanagements, z.B. bei einem Aktienfonds die Auswahl von Aktien innerhalb des Anlagesegmentes ' +
      '(Land, Region, Branche, Stil). Ein positives Alpha deutet daher auf ein besonders erfolgreiches Fondsmanagement hin.',

  'beta': 'Das Beta ist eine Kennziffer, welche die Entwicklung eines Investments in Relation zu einer Benchmark betrachtet. ' +
      'Es misst die Sensitivität des Investments im Hinblick auf Kursveränderungen der Benchmark. Bei einem positiven Beta ' +
      'verläuft das Investment grundsätzlich in Richtung der Benchmark, bei einem negativen Beta grundsätzlich entgegen der Benchmark. ' +
      'Liegt das Beta zwischen 0 und 1, dann ist die Kursentwicklung weniger volatil als bei der zugrunde liegenden Benchmark. Bei ' +
      'einem Beta größer als 1 ist die Volatilität stärker als bei der Benchmark. Beispiel: Beta = 1,3: das Investment legt bei ' +
      'einem Kursgewinn des Vergleichsindexes von 1,0% um 1,3% zu. Entsprechendes gilt für negative Betas.',

  'value_at_risk': 'Der Value at Risk ist eine Kennzahl bzw. eine darauf gestützte Methode zur Quantifizierung, insbesondere der Markt- ' +
      'und Preisrisiken von Kassa- oder derivativen (Finanz-)Instrumenten, sowie Adressausfallrisiken bei Kreditinstrumenten.',

  'volatility': 'Die Volatilität misst die Intensität der Schwankungen eines Fonds innerhalb eines Jahres um den eigenen Mittelwert.',

  'maximum_drawdown': 'Der Maximaler Verlust ist eine Kennzahl, die den größten prozentualen Wertverlust eines Fonds innerhalb eines ' +
      'bestimmten Zeitraums angibt. Der Wert entspricht der Rendite vom teuersten Kurs zum billigsten Kurs im betrachteten Zeitintervall.'

};


export const DEFAULT_ORIGIN = "https://diva.bca.de";


export const getOriginLink = (path) => {
  let origin = getFromStorage(NEW_ORIGIN_KEY) || DEFAULT_ORIGIN;

  if (path) {
    return `${origin}${path}`;
  } else {
    return origin
  }
};


export const getPrivacyPolicyLink = (is_broker, agency_id) => {
  if (is_broker) {
    const origin = getFromStorage(NEW_ORIGIN_KEY);
    return `${origin}/#/Datenschutz`;
  }
  return `https://${agency_id}.diva-app.de/datenschutz`;
};

export const getImprintLink = (is_broker, agency_id) => {
  if (is_broker) {
    const origin = getFromStorage(NEW_ORIGIN_KEY);
    return `${origin}/#/Impressum`;
  }
  return `https://${agency_id}.diva-app.de/impressum`;
};

export const getBackToDivaCustomerLink = (customerId) => {
  const origin = getFromStorage(NEW_ORIGIN_KEY) || DEFAULT_ORIGIN;
  const customer_id = customerId || getFromStorage(CUSTOMER_ID_KEY);
  if (customer_id) {
    return `${origin}/#/Kunden/${customer_id}/${customerId ? 'Kundendaten' : 'Vertraege'}`;
  }

  return origin;
};

export const getCustomerPasswordRecoveryLink = (agency_id) => {
  return `https://${agency_id}.diva-app.de/pwforgotten`;
};

export const PAGE_SIZES = {
  '10': 10,
  '20': 20,
  '40': 40
}

export const PAGE_SIZE_SELECT_OPTIONS = []

for (const [key, value] of Object.entries(PAGE_SIZES)){
  PAGE_SIZE_SELECT_OPTIONS.push({
    id: key,
    label: key,
    value: value
  })
}

export const CHART_COLORS = [
  '#0092E5', '#448744', '#983CA8', '#FCB900', '#FF55BE', '#3CD0DE', '#E56600', '#2330D0', '#9B9B9B', '#2F2F2F'
]

export const getWhiteOrBlackBasedOnHexColor = (hexColor) => {
  // Function that receives hex str and returns black or white font color to look good on it

  // remove # symbol if needed
  let color = (hexColor.charAt(0) === '#') ? hexColor.substring(1, 7) : hexColor;
  // split to rgb
  let r = parseInt(color.substring(0, 2), 16); // hexToR
  let g = parseInt(color.substring(2, 4), 16); // hexToG
  let b = parseInt(color.substring(4, 6), 16); // hexToB

  // formular to calculate Luminance (brightness)
  let brightness = (r * 0.299) + (g * 0.587) + (b * 0.114)
  // if brightness > than constant it is better to use black
  return (brightness > 150) ? 'black' : 'white';
}

// fill object with colors and matching font color for them
let chartColorsWithFontColors = {}
CHART_COLORS.map(chartColor => chartColorsWithFontColors[chartColor] = getWhiteOrBlackBasedOnHexColor(chartColor))

// exporting const
export const CHART_COLORS_WITH_FONTS = chartColorsWithFontColors
export const NO_SECTION_MARK = '*';

export const DEFAULT_SELECT_PRODUCT_TABLE_ACTIONS_NAMES = {
  details: 'Auswählen'
};

export const CHART_MOBILE_VIEW_WIDTH = 500;

export const CHART_RESPONSIVE_RULES = {
  rules: [{
    condition: {
      maxWidth: CHART_MOBILE_VIEW_WIDTH
    },
    chartOptions: {
      navigator: {
        enabled: false
      },
      scrollbar: {
        enabled: false
      },
      yAxis: [{}, {labels: {enabled:false}}]
    }
  }, {
    condition: {
      minWidth: CHART_MOBILE_VIEW_WIDTH
    },
    chartOptions: {
      yAxis: [{}, {labels: {enabled: true}}]
    }
  }]
};